import { useHistory } from "react-router";
import { useState, useCallback } from "react";
import PageComponent from "hub/src/components/common/PageComponent";
import LoadingPage from "hub/src/components/common/LoadingPage";
import { useSecondsTimerWithCallback } from "admin/src/ui/hooks/useSecondsTimerWithCallback";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { getSocietyFileIdOrSlugContract } from "shared/api/contracts/society/societyId/files/fileIdOrSlug";
import { useCustomSession } from "shared/context/CustomSessionContext";
import { useDownloadFileByLink } from "shared/helpers/react/useDownloadFileByLink";

const SECONDS_TO_AUTO_DOWNLOAD = 5;

export type HubSharedUrlsPageRouteParams = {
  fileId: string;
};
const SharedUrlsPage = (
  routeConfig: HubRouteConfig<HubSharedUrlsPageRouteParams>,
) => {
  const history = useHistory();
  const session = useCustomSession();

  const { downloadFileFunction } = useDownloadFileByLink();

  const [isLoading, setIsLoading] = useState(false);

  const { fileId } = routeConfig.routeParams!;

  const autoDownload = useCallback(async () => {
    if (!fileId) return;

    try {
      setIsLoading(true);
      await apiRequestContractHandler(
        getSocietyFileIdOrSlugContract,
        {
          params: {
            societyId: session.societyId!,
            fileIdOrSlug: fileId.toString(),
          },
        },
        undefined,
        undefined,
        undefined,
        (response) => {
          downloadFileFunction({
            url: response.url,
          });
        },
      );

      history.replace("/overview");
    } catch (error) {
      history.replace("/access-denied");
    } finally {
      setIsLoading(false);
    }
  }, [fileId]);

  const onTimerEnd = useCallback(() => {
    autoDownload();
  }, [autoDownload]);

  const { time } = useSecondsTimerWithCallback({
    initialSeconds: SECONDS_TO_AUTO_DOWNLOAD,
    callback: onTimerEnd,
  });

  if (isLoading) return <LoadingPage />;

  return (
    <PageComponent>
      <PageComponent.Content>
        <div className="container-page-padding">
          <div>
            Please wait while your download initiates. It will commence in{" "}
            <b>{time}s</b>.
          </div>
          <div>
            If the download does not begin automatically, you can manually start
            it by clicking <a onClick={autoDownload}>here</a>
          </div>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default SharedUrlsPage;
