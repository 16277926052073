import { createContext, useContext, Dispatch, SetStateAction } from "react";
import { SessionView } from "shared/mappers/database/session/session";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import { SocietyAuthBodyInput } from "shared/api/types/society/[societyId]/auth";

export interface CustomSessionType extends SessionView {
  //both
  loading: boolean;
  setSession: Dispatch<SetStateAction<SessionView | null>>;
  //hub
  logout: () => Promise<void>;
  login: (loginDetails: Required<SocietyAuthBodyInput>) => Promise<void>;
  token: (token: string) => Promise<void>;
  refreshSession: () => Promise<void>;
  queryParams?: RouteParams;
  routeSocietyId?: number | null;
}

const CustomSessionContext = createContext<CustomSessionType | null>(null);

const useCustomSession = (): CustomSessionType => {
  const session = useContext(CustomSessionContext);
  if (session === undefined) {
    throw new Error(
      "useCustomSession must be used within a CustomSessionContext",
    );
  }
  if (session === null) {
    throw new Error("CustomSessionContext is null");
  }
  return session;
};

export { CustomSessionContext, useCustomSession };
