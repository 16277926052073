import dayjs from "dayjs";
import React, { useCallback, useContext } from "react";

import { DATE_FORMAT } from "../constants";
import DatepickerContext from "../contexts/DatepickerContext";

interface SingleDateOptionsProp {
  filterkey?: string;
}

const SingleDateOptions: React.FC<SingleDateOptionsProp> = ({
  filterkey = "",
}) => {
  // Contexts
  const { hideDatepicker, period, changeDatepickerValue, classNames } =
    useContext(DatepickerContext);

  // Functions
  const getClassName = useCallback(() => {
    if (
      typeof classNames !== "undefined" &&
      typeof classNames?.footer === "function"
    ) {
      return classNames.footer();
    }

    return "flex items-center justify-around pb-0.5 pt-0.5 border-t border-neutral-mid-200";
  }, [classNames]);

  return (
    <>
      <div
        data-testid={`datepicker-${filterkey}-single-date-options`}
        className={getClassName()}
      >
        <button
          type="button"
          className="py-0.5 px-0.5 w-8 button-regular-general-filled text-xs"
          id={`button-datepicker-submit-before-${filterkey}`}
          onClick={() => {
            if (period.start === period.end) {
              changeDatepickerValue({
                endDate: dayjs(period.end).format(DATE_FORMAT),
              });
              hideDatepicker();
            }
          }}
          disabled={
            !(period.start && period.end && period.start === period.end)
          }
        >
          <>{"Before"}</>
        </button>
        <button
          type="button"
          className="py-0.5 px-0.5 w-8 button-regular-general-filled text-xs"
          id={`button-datepicker-submit-on-${filterkey}`}
          onClick={() => {
            if (period.start === period.end) {
              changeDatepickerValue({
                startDate: dayjs(period.start).format(DATE_FORMAT),
                endDate: dayjs(period.end).format(DATE_FORMAT),
              });
              hideDatepicker();
            }
          }}
          disabled={
            !(period.start && period.end && period.start === period.end)
          }
        >
          <>{"On"}</>
        </button>
        <button
          type="button"
          className="py-0.5 px-0.5 w-8 button-regular-general-filled text-xs"
          id={`button-datepicker-submit-after-${filterkey}`}
          onClick={() => {
            if (period.start === period.end) {
              changeDatepickerValue({
                startDate: dayjs(period.start).format(DATE_FORMAT),
              });
              hideDatepicker();
            }
          }}
          disabled={
            !(period.start && period.end && period.start === period.end)
          }
        >
          <>{"After"}</>
        </button>
      </div>
    </>
  );
};

export default SingleDateOptions;
