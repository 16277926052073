import { Popover, Transition } from "@headlessui/react";
import { ComponentPropsWithoutRef, Fragment } from "react";

import ChevronDown from "shared/components/icons/chevron/ChevronDown";
type Item = {
  value: string;
  displayName: string;
};

type SelectMenuCheckboxProps = ComponentPropsWithoutRef<typeof Popover> & {
  staticItems: Item[];
  dynamicItems?: Item[];
  title: string;
  onSelect: (e: Item) => void;
  className?: string;
};

const SelectMenuCheckbox = ({
  staticItems,
  dynamicItems,
  onSelect,
  title,
  ...props
}: SelectMenuCheckboxProps) => {
  return (
    <Popover className="relative" {...props}>
      <Popover.Button className="pillartable-filters-selectmenu-button-container flex group">
        <div className="pillartable-filters-selectmenu-button-title mr-0.5">
          {title}
        </div>
        <ChevronDown
          className="pillartable-filters-selectmenu-button-icon w-2"
          aria-hidden="true"
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="pillartable-filters-selectmenu-popover-container absolute z-10 right-0 origin-top-right">
          <div className="flex flex-col gap-y-1">
            {staticItems.length > 0 ? (
              staticItems.map((item, index) => {
                return (
                  <div
                    key={`filterKey-${item.value}-${index}`}
                    className="flex items-center gap-x-1"
                  >
                    <input
                      checked={
                        !!(dynamicItems || staticItems).find(
                          (el) => el.value === item.value,
                        )
                      }
                      onChange={() => onSelect(item)}
                      id={item.value}
                      name={item.displayName}
                      type="checkbox"
                    />
                    <label
                      htmlFor={item.value}
                      className="whitespace-nowrap cursor-pointer"
                    >
                      {item.displayName}
                    </label>
                  </div>
                );
              })
            ) : (
              <span className="whitespace-nowrap text-subtle">No {title}</span>
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default SelectMenuCheckbox;
