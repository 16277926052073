import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import {
  QueryTableData,
  UseQueryRefetch,
} from "shared/components/pillar-table/PillarTable";
import {
  PaginatedResults,
  PaginationRequest,
} from "shared/api/types/pagination";

interface UsePillarTableQueryValuesProps<T extends object> {
  dataQuery: QueryTableData<T> | { data: T[] };
  queryKey: string | string[];
  filters: Record<string, any>;
  filtersRendered: boolean;
  paginationState?: PaginationRequest;
  refetchData?: UseQueryRefetch;
}

export const usePillarTableQueryValues = <
  T extends object,
  ExtraPropsT = Record<string, never>,
>({
  dataQuery,
  queryKey,
  filters,
  filtersRendered,
  paginationState,
  refetchData,
}: UsePillarTableQueryValuesProps<T>) => {
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);

  if (dataQuery instanceof Function && refetchData) {
    throw new Error(
      "You may only provide your own refetch method if you are providing static data.",
    );
  }

  const tableQuery = useQuery<PaginatedResults<T> | T[]>(
    [
      "pillarTable",
      ...(Array.isArray(queryKey) ? queryKey : [queryKey]),
      filters,
      paginationState,
    ],
    async () => {
      const data =
        dataQuery instanceof Function
          ? await dataQuery(filters, paginationState)
          : dataQuery.data;
      return data;
    },
    {
      enabled: filtersRendered,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      networkMode: "always",
      onSuccess: () => setLoadedFirstTime(true),
    },
  );

  useEffect(() => {
    if (refetchData) {
      tableQuery.refetch;
      refetchData(tableQuery.refetch);
    }
  }, [refetchData]);

  const isPaginatedResults = (data: any): data is PaginatedResults<T> => {
    return data && typeof data === "object" && "results" in data;
  };

  const {
    results: tableRowResults = [],
    totalResults = 0,
    dynamicFilterChoices = {},
    page,
    pageSize,
    ...extraProps
  } = (
    isPaginatedResults(tableQuery.data)
      ? tableQuery.data
      : {
          results: tableQuery.data,
          totalResults: tableQuery.data?.length,
          page: 1,
          pageSize: tableQuery.data?.length,
        }
  ) as PaginatedResults<T, ExtraPropsT>;

  const memoizedValues = useMemo(
    () => ({
      loadedFirstTime,
      tableRowResults,
      tableQuery,
      dynamicFilterChoices,
      totalResults,
      extraProps,
    }),
    [
      loadedFirstTime,
      tableRowResults,
      tableQuery,
      dynamicFilterChoices,
      totalResults,
      extraProps,
    ],
  );

  return memoizedValues;
};
