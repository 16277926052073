import { deepCopy } from "ethers/lib/utils";
import {
  get as lodashGet,
  set as lodashSet,
  cloneDeep as lodashCloneDeep,
} from "lodash";
import { ReactElement, useContext, useEffect, useState } from "react";
import ChevronDown from "shared/components/icons/chevron/ChevronDown";
import ChevronUp from "shared/components/icons/chevron/ChevronUp";
import PillarForm from "shared/components/pillar-form/PillarForm";
import PillarTableCheckboxColumn, {
  PillarTableCheckboxColumnProps,
} from "shared/components/pillar-table/display-columns/PillarTableCheckboxColumn";
import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";
import PillarTablePopoverMenu, {
  PillarTableSubmenuItem,
} from "shared/components/pillar-table/PillarTablePopoverMenu";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";
import PillarTableCellContent from "shared/components/pillar-table/cell-content/PillarTableCellContent";
import { getFixedColumnStyle } from "shared/components/pillar-table/helpers/addFixedStyleToColumn";
import { getColumnStyles } from "shared/components/pillar-table/helpers/getColumnStyles";
import { PillarTableNestedTableContext } from "shared/components/pillar-table/nested/PillarTableNestedTableContext";
import { useCustomRouter } from "shared/context/CustomRouterContext";

export interface PillarTableRowProps<T> {
  testidTable?: string;
  rowIndex: number;
  item: T;
  columns: ReactElement<
    PillarTableColumnProps<T> | PillarTableCheckboxColumnProps<T>
  >[];
  menu?:
    | PillarTableSubmenuItem<T>[]
    | ((row: T) => PillarTableSubmenuItem<T>[]);
  handleRowClick?: (row: T) => void;
  handleInlineEdit?: (row: T) => Promise<boolean>;
  handleInlineDelete?: (row: T) => Promise<boolean>;
  refetchTable: () => void;
  handleToggleRowExpansion: () => void;
  fixedColumnWidths?: number[];
}

export type PillarTableRowInnerProps<T> = Omit<
  PillarTableRowProps<T>,
  "item" | "handleToggleRowExpansion"
> & { isRowExpanded: boolean; hasNestedData: boolean };

const PillarTableRow = <T extends object>({
  testidTable,
  item,
  rowIndex,
  columns,
  menu,
  handleRowClick,
  handleInlineEdit,
  handleInlineDelete,
  refetchTable,
  handleToggleRowExpansion,
  fixedColumnWidths,
}: PillarTableRowProps<T>) => {
  const [inlineEditConfirm, setInlineEditConfirm] = useState(false);
  const [rowValue, setRowValue] = useState<T>(deepCopy(item));
  const [editedValue, setEditedValue] = useState<T>(deepCopy(item));
  const { rowData, valueProperty, isRowExpanded } = useContext(
    PillarTableNestedTableContext,
  );
  const nestedData = rowData && lodashGet(rowData, valueProperty);
  const hasNestedData = !!nestedData && !!nestedData.length;

  useEffect(() => {
    setRowValue(deepCopy(item));
    setEditedValue(deepCopy(item));
  }, [item]);
  return (
    <tr
      data-testid={
        testidTable ? `${testidTable}-table-row-${rowIndex}` : undefined
      }
      key={`row-${rowIndex}`}
      className={
        handleRowClick
          ? "pillartable-table-row-interactable group"
          : "pillartable-table-row-general"
      }
      onClick={() => {
        if (handleRowClick) {
          handleRowClick(item);
        }
        if (hasNestedData) handleToggleRowExpansion();
      }}
    >
      <PillarTableRowContext.Provider //TODO: Provide a type argument to the Provider component
        value={{
          rowValue: rowValue,
          editedValue: editedValue,
          updateEditedValue: (newValue: any, valueProperty: string) => {
            const updatedEditedValue = lodashCloneDeep(editedValue); // Deep copy to avoid reference issues
            lodashSet(updatedEditedValue, valueProperty, newValue);
            setEditedValue(updatedEditedValue);
          },
          resetEditedValue: () => {
            setEditedValue(deepCopy(rowValue));
          },
          propagateEditedValue: () => {
            setRowValue(deepCopy(editedValue));
          },
          rowIndex: rowIndex,
          inlineEditConfirm: inlineEditConfirm,
          setInlineEditConfirm: setInlineEditConfirm,
        }}
      >
        <PillarTableRowInner
          testidTable={testidTable}
          rowIndex={rowIndex}
          columns={columns}
          menu={menu}
          handleRowClick={handleRowClick}
          handleInlineEdit={handleInlineEdit}
          handleInlineDelete={handleInlineDelete}
          refetchTable={refetchTable}
          isRowExpanded={isRowExpanded}
          hasNestedData={hasNestedData}
          fixedColumnWidths={fixedColumnWidths}
        />
      </PillarTableRowContext.Provider>
    </tr>
  );
};

// Using this inner component to ensure our useContext has the proper updated values from
// the root parent component. Without inner component the useContext only returns with the
// default values and does not update.
const PillarTableRowInner = <T extends object>({
  testidTable,
  rowIndex,
  columns,
  menu,
  handleInlineEdit,
  handleInlineDelete,
  refetchTable,
  isRowExpanded,
  hasNestedData,
  fixedColumnWidths,
}: PillarTableRowInnerProps<T>) => {
  const [inlineDeleteConfirm, setInlineDeleteConfirm] = useState(false);
  const [deleteSaving, setDeleteSaving] = useState(false);
  const [editSaving, setEditSaving] = useState(false);
  const rowContext = useContext(PillarTableRowContext);

  const router = useCustomRouter();

  return (
    <>
      {columns.map(
        (
          column: ReactElement<
            PillarTableColumnProps<T> | PillarTableCheckboxColumnProps<T>
          >,
          columnIndex: number,
        ) => {
          if (column.props.testid && !testidTable) {
            throw new Error(
              "You must provide a testid prop on the table if you are using testids on the column",
            );
          }

          const navigateToLink = () => {
            if (column.props.linkTo) {
              column.props.linkTo.params = column.props.linkTo.params ?? {};
              Object.keys(column.props.linkTo.params).forEach((key) => {
                column.props.linkTo!.params![key] = lodashGet(
                  rowContext?.rowValue,
                  column.props.linkTo!.params![key] as string,
                  "",
                );
              });
              console.log("column.props.linkTo", column.props.linkTo);
              router.push(column.props.linkTo.path, column.props.linkTo.params);
            }
          };
          return (
            <td
              data-testid={
                testidTable && column.props.testid
                  ? `${testidTable}-table-body-row-column-${column.props.testid}`
                  : undefined
              }
              key={`row-${rowIndex}-column-${columnIndex}`}
              className={`whitespace-nowrap ${
                column.props.linkTo
                  ? "pillartable-table-cell-interactable"
                  : "pillartable-table-cell-general"
              } ${column.props.className ?? ""}
              `}
              style={{
                ...getFixedColumnStyle(
                  column,
                  columnIndex,
                  fixedColumnWidths || [],
                  "hsl(210, 15%, 100%)",
                ),
                ...getColumnStyles(column),
              }}
              onClick={(e) => {
                if (column.type === PillarTableCheckboxColumn) {
                  e.stopPropagation();
                }
                if (column.props.linkTo) {
                  navigateToLink();
                  e.stopPropagation();
                }
              }}
            >
              <div
                className={`${
                  column.props.contentClassName
                    ? column.props.contentClassName
                    : "flex gap-x-1 break-words overflow-visible whitespace-normal"
                }`}
              >
                {hasNestedData && columnIndex === 0 && (
                  <>
                    {isRowExpanded ? (
                      <ChevronUp className="h-2 w-2" stroke="currentColor" />
                    ) : (
                      <ChevronDown className="h-2 w-2" />
                    )}
                  </>
                )}
                <PillarTableCellContent
                  {...column.props}
                  columnIndex={columnIndex}
                />
              </div>
            </td>
          );
        },
      )}
      {handleInlineEdit && (
        <td
          data-testid={
            testidTable
              ? `${testidTable}-table-body-row-column-inlineEdit`
              : undefined
          }
          key={`row-${rowIndex}-column-inlineEdit`}
          className="whitespace-nowrap pillartable-table-cell-edit w-6 pr-1"
        >
          {rowContext?.inlineEditConfirm ? (
            <>
              <PillarForm.Button
                className="button-small-neutral-filled mr-1 mb-1"
                disabled={editSaving}
                onClick={(event) => {
                  event.stopPropagation();
                  rowContext?.resetEditedValue();
                  rowContext?.setInlineEditConfirm(false);
                }}
              >
                Cancel
              </PillarForm.Button>
              <PillarForm.Button
                className="button-small-general-filled"
                disabled={editSaving}
                onClick={async (event) => {
                  event.stopPropagation();
                  setEditSaving(true);
                  const editResponse = await handleInlineEdit(
                    rowContext?.editedValue,
                  );
                  if (editResponse) {
                    rowContext?.propagateEditedValue();
                    rowContext?.setInlineEditConfirm(false);
                    await refetchTable();
                  }
                  setEditSaving(false);
                }}
              >
                Confirm Edit
              </PillarForm.Button>
            </>
          ) : (
            <PillarForm.Button
              className="button-small-general-filled px-1"
              onClick={(event) => {
                event.stopPropagation();
                rowContext?.setInlineEditConfirm(true);
              }}
            >
              Edit
            </PillarForm.Button>
          )}
        </td>
      )}
      {handleInlineDelete && (
        <td
          data-testid={
            testidTable
              ? `${testidTable}-table-body-row-column-inlineDelete`
              : undefined
          }
          key={`row-${rowIndex}-column-inlineDelete`}
          className="whitespace-nowrap pillartable-table-cell-delete w-6 pr-1"
        >
          {inlineDeleteConfirm ? (
            <>
              <PillarForm.Button
                className="button-small-neutral-filled mr-1 mb-1"
                disabled={deleteSaving}
                onClick={(event) => {
                  setInlineDeleteConfirm(false);
                  event.stopPropagation();
                }}
              >
                Cancel
              </PillarForm.Button>
              <PillarForm.Button
                className="button-small-destructive-filled"
                disabled={deleteSaving}
                onClick={async (event) => {
                  setDeleteSaving(true);
                  event.stopPropagation();
                  const deleteResponse = await handleInlineDelete(
                    rowContext?.rowValue,
                  );
                  if (deleteResponse) {
                    refetchTable();
                    setInlineDeleteConfirm(false);
                  }
                  setDeleteSaving(false);
                }}
              >
                Confirm Delete
              </PillarForm.Button>
            </>
          ) : (
            <PillarForm.Button
              className="button-small-destructive-filled px-1"
              onClick={(event) => {
                setInlineDeleteConfirm(true);
                event.stopPropagation();
              }}
            >
              Delete
            </PillarForm.Button>
          )}
        </td>
      )}
      {menu && (
        <td
          data-testid={
            testidTable
              ? `${testidTable}-table-body-row-column-actions`
              : undefined
          }
          key={`row-${rowIndex}-column-inlineDelete`}
          className="whitespace-nowrap pillartable-table-cell-general w-6 relative"
        >
          <PillarTablePopoverMenu<T>
            items={
              typeof menu === "function" ? menu(rowContext?.rowValue) : menu
            }
            refetchTable={refetchTable}
          />
        </td>
      )}
    </>
  );
};

export default PillarTableRow;
