import { DateTime } from "luxon";
import { InvoiceItemView } from "shared/mappers/database/accounting/invoice/invoice-item";
import { ProfileView } from "shared/mappers/database/profile/profile";
import { ProfileAddressView } from "shared/mappers/database/profile/profile-address";
import {
  AliasDateFormats,
  LuxonDateFormats,
  dateMapperFormats,
} from "shared/datetime/dateFormats";
import { getProductNamesFromInvoiceItems } from "shared/helpers/invoice/getProductNamesFromInvoiceItems";
import { defaultAddressFromProfile } from "shared/helpers/profile/address/defaultAddressFromProfile";
import { defaultEmailFromProfile } from "shared/helpers/profile/defaultEmailFromProfile";
import { getDisplayNameHelper } from "shared/helpers/profile/getDisplayNameHelper";
import { getFormattedDegreeData } from "shared/helpers/profile/getFormattedDegreeData";
import { renderTemplateUtil } from "shared/components/template/renderTemplateUtil";
import { PillarDataTableFixedColumn } from "shared/components/pillar-table/helpers/addFixedStyleToColumn";
import { DegreeQuestionFormData } from "shared/components/survey-js-form/questions/degree/IDegreeQuestion";

export interface ColumnsView {
  name: string;
  type: keyof typeof ColumnTypes;
  path: string;
  default?: string;
  pick?: string;
  fixedColumn?: PillarDataTableFixedColumn;
  template?: string;
  contentClassName?: string;
}

export const ColumnTypes = {
  string: "string",
  json: "json",
  number: "number",
  Date: "Date",
  displayName: "displayName",
  email: "email",
  product: "product",
  membership: "membership",
  boolean: "boolean",
  address: "address",
  Month: "Month",
  "string[]": "string[]",
  template: "template",
  degree: "degree",
} as const;

type DisplayColumnMapper = Record<
  keyof typeof ColumnTypes,
  (...args: any[]) => JSX.Element
>;
export const displayColumnsMapper: DisplayColumnMapper = {
  [ColumnTypes.degree]: (
    rowValue: DegreeQuestionFormData[],
    pick: "degreeType" | "university" | "graduationDate",
  ) => {
    return <>{getFormattedDegreeData(rowValue, pick)}</>;
  },
  [ColumnTypes.boolean]: (rowValue: string) => {
    return <>{(rowValue as unknown as boolean) === true ? "Yes" : "No"}</>;
  },
  [ColumnTypes.string]: (rowValue: string) => {
    return <>{rowValue ?? ""}</>;
  },
  [ColumnTypes["string[]"]]: (rowValue: string | string[]) => {
    // this type could return single value in case if element has only one element in result
    return (
      <>{Array.isArray(rowValue) ? rowValue.join(", ") : rowValue ?? ""}</>
    );
  },
  [ColumnTypes.json]: (rowValue: object) => {
    return <>{rowValue ? JSON.stringify(rowValue) : ""}</>;
  },
  [ColumnTypes.number]: (rowValue: string) => {
    return <>{rowValue ?? ""}</>;
  },
  [ColumnTypes.Date]: (rowValue: string) => {
    return (
      <>
        {rowValue
          ? DateTime.fromISO(rowValue).toFormat(LuxonDateFormats.D)
          : ""}
      </>
    );
  },
  [ColumnTypes.Month]: (rowValue: string) => {
    return (
      <>
        {rowValue
          ? DateTime.fromISO(rowValue).toFormat(
              dateMapperFormats[AliasDateFormats.monthLong],
            )
          : ""}
      </>
    );
  },
  [ColumnTypes.displayName]: (rowValue: ProfileView) => {
    return <>{getDisplayNameHelper(rowValue)}</>;
  },
  [ColumnTypes.email]: (rowValue: ProfileView) => {
    return <>{defaultEmailFromProfile(rowValue)}</>;
  },
  [ColumnTypes.product]: (rowValue: InvoiceItemView[]) => {
    return <>{getProductNamesFromInvoiceItems(rowValue)}</>;
  },
  [ColumnTypes.membership]: (rowValue: string) => {
    return <>{rowValue ?? "Non-Member"}</>;
  },
  [ColumnTypes.address]: (
    rowValue: ProfileView,
    property: string,
    use?: string,
  ) => {
    return (
      <>
        {defaultAddressFromProfile(rowValue, use)?.[
          property as keyof ProfileAddressView
        ]?.toString() ?? ""}
      </>
    );
  },
  [ColumnTypes.template]: (
    rowValue: string,
    path: string,
    defaultValue: string,
    template: string,
  ) => {
    return (
      <>
        {renderTemplateUtil({
          template,
          templateData: rowValue,
        })}
      </>
    );
  },
};
