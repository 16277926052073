import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { getSocietyProfileIdContract } from "shared/api/contracts/society/societyId/profiles/profileId";
import { getDisplayNameHelper } from "shared/helpers/profile/getDisplayNameHelper";
import { SessionView } from "shared/mappers/database/session/session";
import { ProfileSearchQuestionValues } from "../profile-search/profileSearchValidationExpressions";

export type CoauthorBlockProps = {
  values: {
    profilesearchmultiple: ProfileSearchQuestionValues[];
  };
  session: SessionView;
  submitterBold?: boolean;
  profileId?: number;
};

const fetchProfileData = async (session: SessionView, profileId?: number) => {
  try {
    return await apiRequestContractHandler(getSocietyProfileIdContract, {
      params: {
        societyId: session.societyId ?? 0,
        profileId: profileId ?? 0,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const initializeProfileData = async (
  session: SessionView,
  profileId?: number,
) => {
  if (profileId) {
    const data = await fetchProfileData(session, profileId);
    return {
      profile_id: data?.id,
      display_name:
        session.societyUser?.firstName + " " + session.societyUser?.lastName,
      affiliation: session.societyUser?.affiliation,
    };
  } else {
    return {
      profile_id: session.profileId!,
      display_name: getDisplayNameHelper(session.profile),
      affiliation: session.societyUser?.affiliation ?? "",
    };
  }
};

const getSortedCoauthorData = (
  data: ProfileSearchQuestionValues[],
  profileData: any,
): ProfileSearchQuestionValues[] => {
  const coauthorData = data.map((item) => {
    if (item.profilesearch?.profile_id === profileData.profile_id) {
      return {
        ...item,
        position: "1",
      };
    }
    return item;
  });

  const withCoauthorBlock = coauthorData
    .filter((item) => item.coauthorBlock)
    .sort((a, b) => Number(a.coauthorBlock) - Number(b.coauthorBlock));
  const withoutCoauthorBlock = coauthorData.filter(
    (item) => !item.coauthorBlock,
  );

  const sortedData = [...withCoauthorBlock, ...withoutCoauthorBlock];

  const uniqueSortedData = sortedData.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) => t.profilesearch?.profile_id === item.profilesearch?.profile_id,
      ),
  );

  return uniqueSortedData;
};

const getUniqueAffiliations = (
  sortedData: ProfileSearchQuestionValues[],
): { [key: string]: number } => {
  const uniqueAffiliations = [
    ...new Set(
      sortedData
        .map((item) => item.profilesearch?.affiliation)
        .filter(
          (affiliation) =>
            affiliation !== undefined &&
            affiliation !== null &&
            affiliation !== "",
        ),
    ),
  ];

  return uniqueAffiliations.reduce(
    (acc: { [key: string]: number }, affiliation, index) => {
      acc[affiliation] = index + 1;
      return acc;
    },
    {},
  );
};

export const generateCoauthorBlock = async ({
  values,
  session,
  submitterBold,
  profileId,
}: CoauthorBlockProps) => {
  const profileData = await initializeProfileData(session, profileId);
  const sortedData = getSortedCoauthorData(
    values.profilesearchmultiple,
    profileData,
  );
  const affiliationMap = getUniqueAffiliations(sortedData);

  return (
    <div className="bg-primary-100 rounded-md p-4">
      <div className="flex flex-wrap gap-y-0.5">
        {sortedData
          .filter((data) => data.profilesearch?.display_name)
          .map((data, index, array) => (
            <span key={data.profilesearch?.profile_id} className="mr-1">
              {data.profilesearch.profile_id === profileId ? (
                <span
                  className={submitterBold ? "text-lg font-bold" : "text-lg"}
                >
                  {data.profilesearch.display_name}
                  {data.profilesearch?.affiliation && (
                    <sup>{affiliationMap[data.profilesearch?.affiliation]}</sup>
                  )}
                </span>
              ) : (
                <span className="text-lg">
                  {data.profilesearch.display_name}
                  {data.profilesearch?.affiliation && (
                    <sup>{affiliationMap[data.profilesearch?.affiliation]}</sup>
                  )}
                </span>
              )}
              {index < array.length - 1 && <span>{", "}</span>}
            </span>
          ))}
      </div>
      <div className="flex flex-wrap pt-6 gap-y-0.5">
        {Object.keys(affiliationMap).map((affiliation, index) => (
          <span className="text-lg mr-1" key={index}>
            <sup>{affiliationMap[affiliation]}</sup>
            {affiliation}
            {index < Object.keys(affiliationMap).length - 1 && (
              <span>{", "}</span>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export const CoauthorBlock = generateCoauthorBlock;
