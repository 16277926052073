import React from "react";
import {
  QueryTableData,
  StaticTableData,
} from "shared/components/pillar-table/PillarTable";
import { PaginatedResults } from "shared/api/types/pagination";
import { FiltersRequest } from "shared/filter-where-clause";
export interface PillarTableHeaderBarContextProps<T extends object> {
  dataQuery?: QueryTableData<T> | StaticTableData<T>;
  tableData?: PaginatedResults<T, any>;
  filters?: FiltersRequest;
  totalRecords?: number;
  multiRowPropertyKeys?: (keyof T)[];
}

export const PillarTableHeaderBarContext = React.createContext<
  PillarTableHeaderBarContextProps<any>
>({} as PillarTableHeaderBarContextProps<any>);
