import { Menu, Transition } from "@headlessui/react";
import React, {
  ComponentPropsWithoutRef,
  Fragment,
  useContext,
  useMemo,
} from "react";
import FileExport from "shared/components/icons/file/FileExport";
import { FiltersRequest } from "shared/filter-where-clause";
import * as XLSX from "xlsx";
import {
  QueryTableData,
  StaticTableData,
} from "shared/components/pillar-table/PillarTable";
import {
  AppExportToExcelBook,
  NewAppExportToExcelBookProps,
} from "shared/components/pillar-table/export/advanced/AppExportToExcelBook";
import { PillarTableExportToExcelContext } from "shared/components/pillar-table/export/PillarTableExportToExcelContext";
import { getWorkbookData } from "shared/components/pillar-table/export/getWorkbookData";
import { PillarTableHeaderBarContext } from "shared/components/pillar-table/header-bar/PillarTableHeaderBarContext";

export type PillarFormMultipleExportToExcelProps<
  T extends object,
  AdditionalQueryTypes extends object[] = [],
> = Omit<ComponentPropsWithoutRef<"button">, "onClick"> & {
  children:
    | Array<React.ReactElement<NewAppExportToExcelBookProps<T>>>
    | React.ReactElement<NewAppExportToExcelBookProps<T>>;
  dataQuery?: QueryTableData<T> | StaticTableData<T>;
  filters?: FiltersRequest;
  totalRecords?: number;
  additionalQueries?: AdditionalQueryTypes extends []
    ? never
    : {
        id: string;
        query:
          | QueryTableData<AdditionalQueryTypes[number]>
          | StaticTableData<AdditionalQueryTypes[number]>;
      }[];
};

export const AppMultipleExportToExcel = <
  T extends object,
  AdditionalQueryTypes extends object[] = [],
>({
  children,
  dataQuery: dataQueryProps,
  filters: filtersProps,
  totalRecords: totalRecordsProps,
  additionalQueries,
  ...props
}: PillarFormMultipleExportToExcelProps<T, AdditionalQueryTypes>) => {
  if (
    (Array.isArray(children) ? children : [children]).some(
      (child) => child.type !== AppExportToExcelBook,
    )
  ) {
    throw new Error(
      "Only AppExportToExcelBook must be used inside AppMultipleExportToExcel",
    );
  }

  const {
    dataQuery: dataQueryContext,
    filters: filtersContext,
    totalRecords: totalRecordsContext,
  } = useContext(PillarTableHeaderBarContext);

  const dataQuery = dataQueryProps || dataQueryContext;
  const filters = filtersProps || filtersContext;
  const totalRecords = totalRecordsProps || totalRecordsContext;

  const menuItems = useMemo<{ name: string }[]>(
    () =>
      React.Children.map(children, (workbookElement) => ({
        name: workbookElement.props.name,
      })),
    [],
  );

  const handleExport = async (workbookName: string) => {
    if (!dataQuery) {
      throw new Error("dataQuery is required");
    }

    if (!filters) {
      throw new Error("filters is required");
    }

    const responseData =
      dataQuery instanceof Function
        ? await dataQuery(filters, {
            page: 1,
            pageSize: totalRecords ?? 100000,
          })
        : dataQuery.data;

    const additionalResponses = (
      await Promise.all(
        (additionalQueries || []).map((additionalQuery) => {
          return additionalQuery.query instanceof Function
            ? additionalQuery.query(
                {},
                {
                  page: 1,
                  pageSize: totalRecords ?? 100000,
                },
              )
            : additionalQuery.query.data;
        }),
      )
    ).reduce<{ [key: string]: AdditionalQueryTypes[number][] }>(
      (acc, curr, index) => {
        const id = (additionalQueries || [])[index].id;
        acc[id] = "results" in curr ? curr.results : curr;

        return acc;
      },
      {},
    );

    const rawData =
      "results" in responseData ? responseData.results : responseData;

    React.Children.map(
      (Array.isArray(children) ? children : [children]).filter(
        (workbookChild) => workbookChild.props.name === workbookName,
      ),
      (workbookChild) => {
        const wb = XLSX.utils.book_new();

        const workSheets = getWorkbookData<T, AdditionalQueryTypes>(
          rawData,
          workbookChild,
          additionalResponses,
        );

        workSheets.forEach((ws) =>
          XLSX.utils.book_append_sheet(wb, ws.sheet, ws.name),
        );

        XLSX.writeFile(wb, `${workbookChild.props.name}.xlsx`);
      },
    );
  };

  return (
    <div className="relative inline-block">
      <Menu as="div" className="inline-block justify-end text-left">
        <Menu.Button
          title="Export"
          className="flex button-regular-general-filled hover:cursor-pointer"
          {...props}
        >
          Export <FileExport className="ml-0.5" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="popovermenu-container mt-2.5 flex-flex-col min-w-15 absolute right-0 z-10 origin-bottom-right">
            {menuItems.map((item, index) => (
              <Menu.Item key={index}>
                {() => (
                  <div
                    className="popovermenu-item flex gap-0.5 items-center pt-1 pb-1 first:pt-0 last:pb-0"
                    onClick={async (e) => {
                      e.preventDefault();
                      await handleExport(item.name);
                    }}
                  >
                    <span>{item.name}</span>
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
      <PillarTableExportToExcelContext.Provider value={true}>
        {children}
      </PillarTableExportToExcelContext.Provider>
    </div>
  );
};
