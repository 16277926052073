import "survey-core/defaultV2.min.css";
import { IonSpinner } from "@ionic/react";
import { Prisma } from "@prisma/client";
import { ENV } from "hub/src/appConstants/env";
import LoadingPage from "hub/src/components/common/LoadingPage";
import PageComponent from "hub/src/components/common/PageComponent";
import { useFormPageValues } from "hub/src/hooks/forms/useFormPageValues";
import useDeviceInfo from "hub/src/hooks/useDeviceInfo";
import { useCustomSession } from "shared/context/CustomSessionContext";
import { HubRouteConfig } from "hub/src/routes/RouteConfigWrapper";
import SurveyJSFormComponent from "shared/components/survey-js-form/SurveyJSFormComponent";

export type HubFormPageRouteParams = {
  formId?: number;
  assignmentId?: number;
  submissionDefinitionId?: number;
  submissionInstanceId?: number;
  conferenceId?: number;
  reviewInstanceAssignmentId?: number;
  reviewSessionId?: number;
  overrideTitleTemplate?: string;
  overrideDescriptionTemplate?: string;
  workflowInstanceId?: number;
  workflowId?: number;
  workflowActionEndpoint?: string;
  defaultPayload?: Prisma.JsonObject;
};
const FormPage = (routeConfig: HubRouteConfig<HubFormPageRouteParams>) => {
  const device = useDeviceInfo();
  const session = useCustomSession();

  const {
    formData,
    formDesign,
    autoSaveChangesCallback,
    overrideCompleteButtonText,
    overrideCompletePageHTML,
    onCompleteCallback,
    overrideTitle,
    overrideDescription,
  } = useFormPageValues({
    ...routeConfig,
  });

  if (!formDesign || !formData) {
    return <LoadingPage />;
  }

  return (
    <PageComponent>
      {device.isNativeApp && (
        <PageComponent.Header showBackButton={true}></PageComponent.Header>
      )}
      <PageComponent.Content>
        <SurveyJSFormComponent
          baseUrl={ENV.REACT_APP_PILLAR_API_URL}
          session={session!}
          overrideTitle={overrideTitle!}
          overrideCompleteButtonText={overrideCompleteButtonText!}
          overrideDescription={overrideDescription!}
          profile={session!.profile!}
          formDesign={formDesign}
          formData={formData}
          onCompleteCallback={onCompleteCallback}
          overrideCompletePageHTML={overrideCompletePageHTML}
          autoSaveChangesCallback={autoSaveChangesCallback}
          spinner={<IonSpinner />}
        />
      </PageComponent.Content>
    </PageComponent>
  );
};

export default FormPage;
