import { InvoiceItemView } from "shared/mappers/database/accounting/invoice/invoice-item";

export const getProductNamesFromInvoiceItems = (
  invoiceItems: InvoiceItemView[],
): string => {
  return invoiceItems
    .map((item) => {
      return item.product?.name;
    })
    .filter((name) => name)
    .join(",");
};
