import { useCustomRouter } from "shared/context/CustomRouterContext";
import { get as lodashGet } from "lodash";
import React, { ComponentPropsWithoutRef } from "react";
import DotsVertical from "shared/components/icons/dot/DotsVertical";
import PillarForm from "shared/components/pillar-form/PillarForm";
import { SessionView } from "shared/mappers/database/session/session";
import { WorkflowView } from "shared/mappers/database/workflow/workflow";
import { WorkflowInstanceView } from "shared/mappers/database/workflow/workflow-instance";
import {
  WorkflowAction,
  WorkflowActionForm,
  WorkflowActionRoles,
  WorkflowActionType,
} from "shared/types/workflow";
import MenuComponent, {
  AppMenuItem,
} from "admin/src/ui/components/menu/MenuComponent";
import { PillarTableRowContext } from "shared/components/pillar-table/PillarTableRowContext";

export type GetMenuOptionsType = {
  possibleActions?: WorkflowAction[];
};

export type PillarTablePossibleActionsColumnProps = {
  formIdPropertyPath?: string;
  submissionDefinitionIdPropertyPath?: string;
  submissionInstanceIdPropertyPath?: string;
  conferenceIdPropertyPath?: string;
  reviewInstanceAssignmentIdPropertyPath?: string;
  reviewSessionIdPropertyPath?: string;
  overrideTitleTemplateIdPropertyPath?: string;
  overrideDescriptionTemplateIdPropertyPath?: string;
  workflowInstancePropertyPath: string;
  workflowPropertyPath: string;
  session: SessionView;
} & ComponentPropsWithoutRef<"div">;

const PillarTablePossibleActionsColumnProps = ({
  className,
  formIdPropertyPath,
  submissionDefinitionIdPropertyPath,
  submissionInstanceIdPropertyPath,
  conferenceIdPropertyPath,
  reviewInstanceAssignmentIdPropertyPath,
  reviewSessionIdPropertyPath,
  overrideTitleTemplateIdPropertyPath,
  overrideDescriptionTemplateIdPropertyPath,
  workflowPropertyPath,
  workflowInstancePropertyPath,
  //TODO: Instead of passing Session in we should have a shared hooked like useCustomRouter works on either admin or userhub
  session,
  ...props
}: PillarTablePossibleActionsColumnProps) => {
  const router = useCustomRouter();

  const rowContext = React.useContext(PillarTableRowContext);
  const getIdFromPropertyPath = (propertyPath?: string) => {
    return propertyPath
      ? lodashGet(rowContext?.rowValue, propertyPath)
      : undefined;
  };
  const formId = getIdFromPropertyPath(formIdPropertyPath);
  const submissionDefinitionId = getIdFromPropertyPath(
    submissionDefinitionIdPropertyPath,
  );
  const submissionInstanceId = getIdFromPropertyPath(
    submissionInstanceIdPropertyPath,
  );
  const conferenceId = getIdFromPropertyPath(conferenceIdPropertyPath);
  const reviewInstanceAssignmentId = getIdFromPropertyPath(
    reviewInstanceAssignmentIdPropertyPath,
  );
  const reviewSessionId = getIdFromPropertyPath(reviewSessionIdPropertyPath);
  const overrideTitleTemplate = getIdFromPropertyPath(
    overrideTitleTemplateIdPropertyPath,
  );
  const overrideDescriptionTemplate = getIdFromPropertyPath(
    overrideDescriptionTemplateIdPropertyPath,
  );
  const workflowInstance = lodashGet(
    rowContext?.rowValue,
    workflowInstancePropertyPath,
  ) as WorkflowInstanceView;
  const workflow = lodashGet(
    rowContext?.rowValue,
    workflowPropertyPath,
  ) as WorkflowView;
  const userHasRole = (workflowAction: WorkflowAction) => {
    if (session.adminMode) {
      return true;
    }
    return workflowAction.roles.includes(WorkflowActionRoles.User);
  };

  const getMenuOptions = ({
    possibleActions,
  }: GetMenuOptionsType): AppMenuItem[] => {
    const menuOptions: AppMenuItem[] = [];
    if (possibleActions) {
      possibleActions.forEach((workflowAction: WorkflowAction) => {
        if (
          userHasRole(workflowAction) &&
          workflowAction.type === WorkflowActionType.FORM
        ) {
          const workflowActionForm = workflowAction as WorkflowActionForm;

          menuOptions.push({
            label: workflowAction.title,
            itemClassName: workflowAction.className,
            onSelect: () => {
              //TODO: This only works for userhub forms,  but we want to use this on Admin as well which has a more static /profile/:profileId/form/:formId route
              router.push(workflowActionForm.userhubFormRoute, {
                ...workflowAction,
                societyId: session.societyId!,
                profileId: session.profileId!,
                workflowActionEndpoint: workflowAction.endpoint,
                formId,
                submissionDefinitionId,
                submissionInstanceId,
                conferenceId,
                reviewInstanceAssignmentId,
                reviewSessionId,
                overrideTitleTemplate,
                overrideDescriptionTemplate,
                workflowId: workflow.workflowId,
                workflowInstanceId: workflowInstance.workflowInstanceId,
              });
            },
          });
        }
      });
    }
    return menuOptions;
  };

  if (!workflowInstance) {
    return <></>;
  }

  const menuItems = getMenuOptions({
    possibleActions: workflowInstance?.possibleActions,
  });

  if (!menuItems.length) {
    return <></>;
  }

  const [firstMenuItem, ...restMenuItems] = menuItems;

  return (
    <div className="flex item-center gap-x-1">
      <PillarForm.Button
        title={firstMenuItem.label}
        className={firstMenuItem.itemClassName}
        icon={firstMenuItem.icon as React.ReactElement}
        onClick={firstMenuItem.onSelect}
      >
        {firstMenuItem.label}
      </PillarForm.Button>

      {!!restMenuItems.length && (
        <MenuComponent
          menuItems={restMenuItems}
          overrideClassName="flex"
          wrapperClassName="!inline-flex"
          buttonIcon={<DotsVertical className="text-neutral-mid-550 w-2 h-2" />}
        />
      )}
    </div>
  );
};

export default PillarTablePossibleActionsColumnProps;
