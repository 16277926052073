import { exportColumnsMapper } from "shared/components/pillar-table/custom-reports/exportColumnsMapper";
import { get as lodashGet } from "lodash";
import { ReportResponseType } from "shared/api/types/society/[societyId]/reports/[reportId]";
import { CustomReportView } from "shared/mappers/database/reports/report";
import PillarTable from "shared/components/pillar-table/PillarTable";
import { generateExportFileName } from "shared/components/pillar-table/export/generateExportFileName";
import PillarTableHeaderBar from "shared/components/pillar-table/header-bar/PillarTableHeaderBar";
import {
  ColumnsView,
  ColumnTypes,
} from "shared/components/pillar-table/custom-reports/displayColumnsMapper";
import { PaginatedResults } from "shared/api/types/pagination";

interface CustomReportHeaderProps {
  customReportResponse: PaginatedResults<ReportResponseType, CustomReportView>;
}

const CustomReportHeader: React.FC<CustomReportHeaderProps> = ({
  customReportResponse,
}) => {
  return customReportResponse?.customReportId ? (
    <PillarTableHeaderBar>
      <div className="mb-1 w-full flex justify-between items-center">
        <div className="flex flex-shrink-1 flex-0">
          <h1>Report - {customReportResponse?.reportName}</h1>
        </div>
        {customReportResponse?.exportColumns && (
          <PillarTable.ExportToExcel
            worksheetName={generateExportFileName(
              customReportResponse!.reportName!,
            )}
          >
            {customReportResponse.exportColumns.map((item: ColumnsView) => (
              <PillarTable.ExportToExcelColumn key={item.name} header={item.name}>
                {(row: ColumnsView) => {
                  const value = lodashGet(row, item.path);
                  const rowValue = item.path && !item.default ? value : row;
                  const pipe =
                    exportColumnsMapper[item.type] ??
                    exportColumnsMapper[ColumnTypes.string];

                  const output = pipe(
                    rowValue,
                    item.path,
                    item.default ?? item.pick ?? "",
                    item?.template ?? "",
                  );
                  return output;
                }}
              </PillarTable.ExportToExcelColumn>
            ))}
          </PillarTable.ExportToExcel>
        )}
      </div>
    </PillarTableHeaderBar>
  ) : null;
};

export default CustomReportHeader;
