import dayjs from "dayjs";
import React, { useContext } from "react";

import { RoundedButton } from "admin/src/ui/components/common/datepicker/src/components/utils";
import { MONTHS } from "admin/src/ui/components/common/datepicker/src/constants";
import DatepickerContext from "admin/src/ui/components/common/datepicker/src/contexts/DatepickerContext";
import { loadLanguageModule } from "admin/src/ui/components/common/datepicker/src/helpers";
interface Props {
  currentMonth: number;
  clickMonth: (month: number) => void;
}

const Months: React.FC<Props> = ({ currentMonth, clickMonth }) => {
  const { i18n } = useContext(DatepickerContext);
  loadLanguageModule(i18n);
  return (
    <div className="w-full grid grid-cols-2 mt-2">
      {MONTHS.map((item) => (
        <RoundedButton
          key={item}
          padding="py-0.75"
          onClick={() => {
            clickMonth(item);
          }}
          active={currentMonth === item}
        >
          <>{dayjs(`2022-${item}-01`).locale(i18n).format("MMM")}</>
        </RoundedButton>
      ))}
    </div>
  );
};

export default Months;
