import { DegreeQuestionFormData } from "shared/components/survey-js-form/questions/degree/IDegreeQuestion";

export const getFormattedDegreeData = (
  degree: DegreeQuestionFormData[],
  value: "degreeType" | "university" | "graduationDate",
) => {
  const values = degree?.map((item) => {
    if (value === "graduationDate") {
      return item[value];
    }
    return item[value] ?? item[`${value}-Comment`];
  });
  return values?.filter((item) => item).join(", ");
};
