import { get } from "lodash";
import React, { ComponentPropsWithoutRef, useContext } from "react";

import { PillarTableNestedTableContext } from "shared/components/pillar-table/nested/PillarTableNestedTableContext";
import { DeepKeys } from "shared/types/deepkeys";
import PillarTable from "shared/components/pillar-table/PillarTable";
import { PillarTableColumnProps } from "shared/components/pillar-table/PillarTableColumn";

export type PillarTableNestedTableProps<T extends object> =
  ComponentPropsWithoutRef<"div"> & {
    children: Array<React.ReactElement<PillarTableColumnProps<T>>>;
    valueProperty: DeepKeys<T>;
    pagination?: boolean;
    urlParams?: boolean;
  };

const PillarTableNestedTable = <T extends object>({
  valueProperty,
  children,
  pagination = false,
  urlParams = false,
}: PillarTableNestedTableProps<T>) => {
  const { rowData, isRowExpanded, parentTableColumnsLength } = useContext(
    PillarTableNestedTableContext,
  );

  const nestedData = rowData && get(rowData, valueProperty);
  if (!nestedData.length) return null;

  return (
    <tr className={`${!isRowExpanded ? "hidden" : ""}`}>
      <td colSpan={parentTableColumnsLength}>
        <div
          className="p-2 bg-neutral-light"
          data-testid={`row-${rowData?.id}-nested-table`}
        >
          <PillarTable
            className="w-full"
            dataQuery={{
              data: nestedData,
            }}
            queryKey={`nested-table-row-${rowData?.id}`}
            pagination={pagination}
            urlParams={urlParams}
          >
            {children}
          </PillarTable>
        </div>
      </td>
    </tr>
  );
};

export default PillarTableNestedTable;