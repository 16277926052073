import { useCustomRouter } from "shared/context/CustomRouterContext";
import { useCustomSession } from "shared/context/CustomSessionContext";
import React from "react";
import { useParams } from "react-router";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";
import { AppRouteConfigView } from "shared/mappers/database/app-route/app-route-config/app-route-config";

export interface HubRouteConfig<RP = RouteParams>
  extends Partial<Omit<AppRouteConfigView, "routeParams">> {
  routeParams?: RP;
}

type RouteConfigWrapperProps = {
  children: React.ReactNode;
  routeConfig?: Partial<AppRouteConfigView>;
};

export const RouteConfigWrapper = ({
  children,
  routeConfig,
}: RouteConfigWrapperProps) => {
  const dynamicParams = useParams<{ [key: string]: string }>();
  const router = useCustomRouter();
  const session = useCustomSession();
  const parsedParams: Record<string, string | number | boolean> = {};

  // const tokenValue = queryParams.get("token") ?? queryParams.get("impersonate");
  // useEffect(() => {
  //   if (tokenData && "usageLogin" in tokenData && tokenData.usageLogin) {
  //     const loginUsage = tokenData.usageLogin;
  //     let url = loginUsage.routePath;
  //     for (const key in loginUsage.pathParam) {
  //       url = url.replace(`:${key}`, loginUsage.pathParam[key].toString());
  //     }
  //   }
  // }, [tokenData]);
  //Look for token in URL

  Object.keys(dynamicParams).forEach((key) => {
    const value = dynamicParams[key];
    if (!isNaN(Number(value))) {
      parsedParams[key] = Number(value);
    } else if (value === "true" || value === "false") {
      parsedParams[key] = value === "true";
    } else {
      parsedParams[key] = value;
    }
  });

  const mergedRouteParams = {
    ...session.queryParams,
    ...routeConfig?.routeParams,
    ...parsedParams,
    ...(router.state || {}),
  } as RouteParams;
  return React.cloneElement(children as React.ReactElement<HubRouteConfig>, {
    ...routeConfig,
    routeParams: mergedRouteParams,
  });
};

export default RouteConfigWrapper;
