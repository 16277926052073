import { Popover, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { ComponentPropsWithoutRef, Fragment, useEffect, useState } from "react";
import ChevronDown from "shared/components/icons/chevron/ChevronDown";
import withFilterComponent from "shared/components/pillar-table/filters/withFilterComponent";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
import { FilterChoice } from "shared/filter-where-clause";

type PillarTableFilterSelectMenuCheckboxProps<T> = ComponentPropsWithoutRef<
  typeof Popover
> & {
  initalValue?: string[];
  title: string;
  filterKey: string;
  choices?: FilterChoice[];
  className?: string;
  popupContainerClassName?: string;
  queryKey?: string;
  selectOptionQuery?: () => Promise<FilterChoice[]>;
};

const PillarTableFilterSelectMenuCheckbox = <T extends { [key: string]: any }>({
  initalValue,
  filterKey,
  title,
  choices,
  queryKey,
  className,
  popupContainerClassName,
  selectOptionQuery,
  ...props
}: PillarTableFilterSelectMenuCheckboxProps<T>) => {
  const filterContext = usePillarTableQueryContext();
  const { setFilters, filters } = filterContext;
  const [filterValue, setFilterValue] = useState<string[]>(
    Array.isArray(filters[filterKey])
      ? (filters[filterKey] as string[])
      : initalValue ?? []
  );

  const resetFilter = () => setFilterValue([]);

  const selectOptions = useQuery<FilterChoice[]>(
    [
      "selectOptions",
      filterKey,
      queryKey,
      filterContext.dynamicFilterChoices,
      filterContext.queryKey,
    ],
    () => {
      if (selectOptionQuery) {
        return (selectOptionQuery && selectOptionQuery()) ?? [];
      }

      const dynamicFilterOption =
        filterContext?.dynamicFilterChoices?.[filterKey];
      return choices ?? dynamicFilterOption ?? [];
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (filters[filterKey] != filterValue) {
      setFilters({ ...filters, [filterKey]: filterValue });
    }
  }, [filterValue]);

  return (
    <Popover
      {...props}
      id={`filter-select-popover-${queryKey ?? filterKey}`}
      className={`relative rounded mr-1 ${className}`}
    >
      <Popover.Button
        id={`filter-select-popover-button-${filterKey}`}
        className="pillartable-filters-selectmenu-button-container flex group"
      >
        <div
          data-testid={`filter-select-popover-button-${filterKey}`}
          className="pillartable-filters-selectmenu-button-title mr-0.25"
        >
          {title}
        </div>
        {filterValue.length > 0 && (
          <div className="pillartable-filters-selectmenu-button-count mx-0.25 mt-0.25">
            {filterValue.length}
          </div>
        )}
        <ChevronDown
          className="pillartable-filters-selectmenu-button-icon h-2.5 w-2.5"
          aria-hidden="true"
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel
          className={`pillartable-filters-selectmenu-popover-container min-w-[150px] absolute right-0 z-10 origin-bottom-right border border-neutral-light-500 ${popupContainerClassName}`}
        >
          {selectOptions.data && selectOptions.data.length > 0 ? (
            <div className="flex flex-col gap-y-1">
              {selectOptions.data.map((item) => {
                const value = item.value;

                return (
                  <div key={`filterKey-${value}`} className="flex items-center">
                    <input
                      checked={filterValue.includes(value.toString())}
                      value={value}
                      onChange={(e) => {
                        const tempFilterValue = filterValue.filter(
                          (item) => item !== value.toString()
                        );
                        if (e.target.checked) {
                          tempFilterValue.push(value.toString());
                        }
                        setFilterValue(tempFilterValue);
                      }}
                      id={`filter-checkbox-${filterKey}-${value}`}
                      data-testid={`filter-checkbox-${filterKey}-${value}`}
                      name={`filter-checkbox-${filterKey}-${value}`}
                      type="checkbox"
                    />
                    <label
                      htmlFor={`filter-checkbox-${filterKey}-${value}`}
                      data-testid={`filter-label-${filterKey}-${value}`}
                      className="ml-1 whitespace-nowrap cursor-pointer"
                    >
                      {item.display}
                    </label>
                  </div>
                );
              })}
              <button
                className="mt-2 w-full cursor-pointer text-xs text-neutral-mid font-semibold hover:text-status-danger"
                type="button"
                onClick={resetFilter}
              >
                Clear
              </button>
            </div>
          ) : (
            <span className="inline-block w-full text-center whitespace-nowrap text-subtle">
              No filters
            </span>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default withFilterComponent(PillarTableFilterSelectMenuCheckbox);
