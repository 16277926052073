import { Popover, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { ComponentPropsWithoutRef, useEffect, useState, Fragment } from "react";
import ChevronDown from "shared/components/icons/chevron/ChevronDown";
import { FilterChoice, FiltersRequest } from "shared/filter-where-clause";
import withFilterComponent from "shared/components/pillar-table/filters/withFilterComponent";
import { usePillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";

const getPathKey = (path: FilterChoice[]) =>
  path.map((pathObj) => `${pathObj.filterKey}-${pathObj.value}`).join("-");
type PillarTableFilterTreeSelectMenuCheckboxProps = ComponentPropsWithoutRef<
  typeof Popover
> & {
  initalValue?: string | number;
  title: string;
  filterKey: string;
  className?: string;
  queryKey?: string;
  selectOptionQuery?: () => Promise<FilterChoice[]>;
};

const PillarTableFilterTreeSelectMenuCheckbox = <
  T extends { [key: string]: any },
>({
  initalValue,
  filterKey,
  title,
  selectOptionQuery,
  queryKey,
  ...props
}: PillarTableFilterTreeSelectMenuCheckboxProps) => {
  const filterContext = usePillarTableQueryContext();
  const { setFilters, filters } = filterContext;

  useEffect(() => {
    if (initalValue) {
      setFilters({
        ...filters,
        [filterKey]: [initalValue] as string[] | number[],
      });
    }
  }, [initalValue]);

  const resetFilter = () => setFilters({ ...filters, [filterKey]: [] });

  const selectOptions = useQuery<FilterChoice[]>(
    [
      "selectOptions",
      filterKey,
      queryKey,
      filterContext.dynamicFilterChoices,
      filterContext.queryKey,
    ],
    () => {
      if (selectOptionQuery) {
        return (selectOptionQuery && selectOptionQuery()) ?? [];
      }
      const dynamicFilterOptions =
        filterContext?.dynamicFilterChoices?.[filterKey];
      return dynamicFilterOptions ?? [];
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const [itemIndexesToShow, setItemIndexesToShow] = useState<string[]>([]);

  const getAllDescendantChoiceValues = (
    node: FilterChoice,
    filterKey: string,
  ): {
    filterKey: string;
    value: string | number;
  }[] => {
    if (node.choices && node.choices.length > 0) {
      return node.choices.flatMap((choice) =>
        getAllDescendantChoiceValues(choice, node.filterKey ?? filterKey),
      );
    } else if (node.value !== undefined) {
      return [
        {
          filterKey,
          value: node.value,
        },
      ];
    } else {
      return [];
    }
  };

  const FilterItemWithChoices = ({
    filterChoice,
    path,
  }: {
    path: FilterChoice[];
    filterChoice: FilterChoice;
  }) => {
    const pathKey = getPathKey(path);
    const currentPath = path[path.length - 1];

    const isCurrentItemOpened = itemIndexesToShow.includes(pathKey);
    const allDescendantChoiceValues = getAllDescendantChoiceValues(
      filterChoice,
      currentPath.filterKey!,
    );

    const getFilterValues = (filterKey: string): (string | number)[] =>
      (filters[filterKey] as (string | number)[]) ?? [];

    const isChecked = allDescendantChoiceValues.every((cv) => {
      const filterValues = getFilterValues(cv.filterKey);
      return (filterValues as (string | number)[]).includes(cv.value);
    });

    const isIndeterminate =
      !isChecked &&
      allDescendantChoiceValues.some((cv) => {
        const filterValues = getFilterValues(cv.filterKey);
        if (Array.isArray(filterValues)) {
          return (filterValues as (string | number)[]).includes(cv.value);
        }
        return false;
      });

    return (
      <>
        {filterChoice.value && !filterChoice.choices ? (
          <div className="flex items-center">
            <input
              checked={isChecked}
              value={currentPath.value}
              onChange={(e) => {
                const filterValues = getFilterValues(currentPath.filterKey!);
                const tempFilterValue =
                  filterValues?.filter((item) => item !== filterChoice.value) ??
                  [];
                if (e.target.checked) {
                  tempFilterValue.push(filterChoice.value);
                }
                setFilters({
                  ...filters,
                  [currentPath.filterKey!]: tempFilterValue as
                    | string[]
                    | number[],
                });
              }}
              id={`filter-checkbox-${filterKey}-${pathKey}`}
              data-testid={`filter-checkbox-${pathKey}`}
              name={`filter-checkbox-${filterKey}-${pathKey}`}
              type="checkbox"
            />
            <label
              htmlFor={`filter-checkbox-${filterKey}-${pathKey}`}
              data-testid={`filter-label-${pathKey}-${currentPath.display}`}
              className="ml-1 whitespace-nowrap cursor-pointer"
            >
              {filterChoice.display}
            </label>
          </div>
        ) : (
          <div>
            <div className="flex items-center">
              <input
                checked={isChecked}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = isIndeterminate;
                  }
                }}
                value={currentPath.value}
                onChange={(e) => {
                  // Set the top level filter value making sure our selection is not duplicated
                  const tmpFilters: FiltersRequest = {};
                  tmpFilters[currentPath.filterKey!] = getFilterValues(
                    currentPath.filterKey!,
                  ).filter((item) => item !== filterChoice.value) as
                    | string[]
                    | number[];
                  if (e.target.checked) {
                    (
                      tmpFilters[currentPath.filterKey!] as (string | number)[]
                    ).push(filterChoice.value as string | number);
                    allDescendantChoiceValues.forEach((cv) => {
                      console.log("descendant", cv);
                      //For each descendant choice value, we remove it from the filter values and add it back
                      tmpFilters[cv.filterKey] = getFilterValues(
                        cv.filterKey,
                      ).filter((item: string | number) => item !== cv.value) as
                        | string[]
                        | number[];
                      (tmpFilters[cv.filterKey] as (string | number)[]).push(
                        cv.value as string | number,
                      );
                    });
                  } else {
                    //If the current filter is unchecked, we remove all the descendant values from the filter
                    allDescendantChoiceValues.forEach((cv) => {
                      tmpFilters[cv.filterKey] = getFilterValues(
                        cv.filterKey,
                      ).filter((item: string | number) => item !== cv.value) as
                        | string[]
                        | number[];
                    });
                  }
                  setFilters({ ...filters, ...tmpFilters });
                }}
                id={`filter-checkbox-${pathKey}`}
                data-testid={`filter-checkbox-${pathKey}`}
                name={`filter-checkbox-${pathKey}`}
                type="checkbox"
              />
              <label
                htmlFor={`filter-checkbox-${pathKey}`}
                className="ml-1 flex items-center whitespace-nowrap cursor-pointer"
                data-testid={`filter-label-${pathKey}-${currentPath.display}`}
                onClick={() => {
                  if (isCurrentItemOpened) {
                    setItemIndexesToShow((prev) =>
                      prev.filter((item) => item !== pathKey),
                    );
                  } else {
                    setItemIndexesToShow((prev) => [...prev, pathKey]);
                  }
                }}
              >
                {filterChoice.display}
              </label>
              <ChevronDown
                className={`pillartable-filters-selectmenu-button-icon h-2.5 w-2.5 ${
                  isCurrentItemOpened ? "rotate-180" : ""
                }`}
                aria-hidden="true"
                onClick={() => {
                  if (isCurrentItemOpened) {
                    setItemIndexesToShow((prev) =>
                      prev.filter((item) => item !== pathKey),
                    );
                  } else {
                    setItemIndexesToShow((prev) => [...prev, pathKey]);
                  }
                }}
              />
            </div>
            {isCurrentItemOpened &&
              filterChoice.choices &&
              filterChoice.choices.length > 0 && (
                <div className="ml-3">
                  {renderTree(filterChoice, [...path])}
                </div>
              )}
          </div>
        )}
      </>
    );
  };

  const renderTree = (filter: FilterChoice, parentPath: FilterChoice[]) => {
    return filter.choices?.map((item) => {
      const currentPath: FilterChoice[] = [
        ...parentPath,
        {
          display: "",
          value: item.value,
          filterKey: filter.filterKey!,
        },
      ];
      return (
        <FilterItemWithChoices
          key={`filterKey-${getPathKey(currentPath)}`}
          filterChoice={item}
          path={currentPath}
        />
      );
    });
  };

  return (
    <Popover
      {...props}
      id={`filter-select-popover-${queryKey ?? filterKey}`}
      data-testid={`filter-select-popover-${queryKey ?? filterKey}`}
      className="relative rounded mr-1"
    >
      <Popover.Button
        id={`filter-select-popover-button-${queryKey ?? filterKey}`}
        data-testid={`filter-select-popover-button-${queryKey ?? filterKey}`}
        className="pillartable-filters-selectmenu-button-container flex group"
      >
        <div className="pillartable-filters-selectmenu-button-title mr-0.25">
          {title}
        </div>
        {filters[filterKey] &&
          (filters[filterKey] as string[] | number[])!.length > 0 && (
            <div className="pillartable-filters-selectmenu-button-count mx-0.25 mt-0.25">
              {(filters[filterKey] as string[] | number[]).length}
            </div>
          )}
        <ChevronDown
          className="pillartable-filters-selectmenu-button-icon w-2"
          aria-hidden="true"
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="pillartable-filters-selectmenu-popover-container absolute z-10 origin-top-right ">
          {selectOptions.data && selectOptions.data.length > 0 && (
            <div className="flex flex-col gap-y-1">
              {renderTree(
                {
                  display: title,
                  value: "",
                  choices: selectOptions.data,
                  filterKey: filterKey,
                },
                [],
              )}
            </div>
          )}
          {filters ? (
            <button
              className="w-full cursor-pointer text-xs text-neutral-mid font-semibold hover:text-status-danger"
              type="button"
              onClick={resetFilter}
            >
              Clear
            </button>
          ) : (
            <span className="whitespace-nowrap text-subtle">No filters</span>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default withFilterComponent(PillarTableFilterTreeSelectMenuCheckbox);
