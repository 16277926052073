import { ErrorMessage } from "formik";
import React, { useState } from "react";
import ChevronDown from "shared/components/icons/chevron/ChevronDown";
import ChevronUp from "shared/components/icons/chevron/ChevronUp";
import ToolTip from "admin/src/ui/components/common/ToolTip";
import Info from "shared/components/icons/Info";

//This is one of the few cases we may NOT want to do ComponentPropsWithoutRef.
//The reason is we use this as a wrapper around other inputs where those inputs
//should really be the ones taking the ComponentPropsWithoutRef<"input">.
export type PillarFormInputWrapperProps = {
  name: string;
  label?: string | JSX.Element;
  labelClassName?: string;
  additionalClasses?: string;
  children?: React.ReactNode;
  handleChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  testid?: string;
  tooltip?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder?: any; //TODO: STYLING Remove this. It is not used here and should not be defined here.
  required?: boolean; //TODO: STYLING Remove this. It is not used here and should not be defined here.
  collapse?: boolean;
  errorMessage?: boolean;
};

const PillarFormInputWrapper = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  children,
  testid,
  tooltip,
  collapse = false,
  errorMessage,
}: PillarFormInputWrapperProps) => {
  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <div
      className={`flex flex-col ${additionalClasses ?? ""}`}
      data-testid={testid ? `${testid}-wrapper` : undefined}
    >
      {label && (
        <label
          className={`mb-0.25 ${labelClassName ?? "text-filter-label"} ${
            collapse ? "inline-flex items-center gap-x-0.5 cursor-pointer" : ""
          }`}
          htmlFor={name}
          onClick={() => {
            if (!collapse) return;
            setIsCollapse(!isCollapse);
          }}
        >
          {collapse &&
            (isCollapse ? (
              <ChevronUp className="h-2.5 w-2.5" />
            ) : (
              <ChevronDown className="h-2.5 w-2.5" />
            ))}
          <div className="flex items-center">
            {label}
            {tooltip && (
              <ToolTip
                className="cursor-pointer ml-1 flex items-center"
                tooltipClassName="mt-0.25 py-1 px-2 bg-neutral-light text-xs rounded-md border border-neutral-mid-300"
                tooltip={<div className="flex flex-col text-sm">{tooltip}</div>}
                placement="top-start"
              >
                <Info className="text-status-general-tint h-3 w-3" />
              </ToolTip>
            )}
          </div>
        </label>
      )}
      {collapse ? isCollapse && children : children}
      {errorMessage && (
        <ErrorMessage
          name={name}
          component="span"
          className="text-danger-small"
        />
      )}
    </div>
  );
};

export default PillarFormInputWrapper;
