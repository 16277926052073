import { DateTime } from "luxon";
import { InvoiceItemView } from "shared/mappers/database/accounting/invoice/invoice-item";
import { ProfileView } from "shared/mappers/database/profile/profile";
import { ProfileAddressView } from "shared/mappers/database/profile/profile-address";
import { DegreeQuestionFormData } from "shared/components/survey-js-form/questions/degree/IDegreeQuestion";
import {
  AliasDateFormats,
  LuxonDateFormats,
  dateMapperFormats,
} from "shared/datetime/dateFormats";
import { getProductNamesFromInvoiceItems } from "shared/helpers/invoice/getProductNamesFromInvoiceItems";
import { defaultAddressFromProfile } from "shared/helpers/profile/address/defaultAddressFromProfile";
import { defaultEmailFromProfile } from "shared/helpers/profile/defaultEmailFromProfile";
import { getDisplayNameHelper } from "shared/helpers/profile/getDisplayNameHelper";
import { getFormattedDegreeData } from "shared/helpers/profile/getFormattedDegreeData";
import { ColumnTypes } from "shared/components/pillar-table/custom-reports/displayColumnsMapper";
import { renderTemplateUtil } from "shared/components/template/renderTemplateUtil";

type ExportColumnMapper = Record<
  keyof typeof ColumnTypes,
  (...args: any[]) => string
>;
export const exportColumnsMapper: ExportColumnMapper = {
  //boolean, degree, "string[]" as missing
  [ColumnTypes.boolean]: (rowValue: string) => {
    return `${(rowValue as unknown as boolean) === true ? "Yes" : "No"}`;
  },
  [ColumnTypes.degree]: (
    rowValue: DegreeQuestionFormData[],
    pick: "degreeType" | "university" | "graduationDate",
  ) => {
    return `${getFormattedDegreeData(rowValue, pick)}`;
  },
  [ColumnTypes["string[]"]]: (rowValue: string[]) => {
    return `${rowValue?.join(", ") ?? ""}`;
  },
  [ColumnTypes.json]: (rowValue: object) => {
    return `${rowValue ? JSON.stringify(rowValue) : ""}`;
  },
  [ColumnTypes.string]: (rowValue: string) => {
    return `${rowValue ?? ""}`;
  },
  [ColumnTypes.number]: (rowValue: string) => {
    return `${rowValue ?? ""}`;
  },
  [ColumnTypes.Date]: (rowValue: string) => {
    return `${
      rowValue ? DateTime.fromISO(rowValue).toFormat(LuxonDateFormats.D) : ""
    }`;
  },
  [ColumnTypes.Month]: (rowValue: string) => {
    return `${
      rowValue
        ? DateTime.fromISO(rowValue).toFormat(
            dateMapperFormats[AliasDateFormats.monthLong],
          )
        : ""
    }`;
  },
  [ColumnTypes.displayName]: (rowValue: ProfileView) => {
    return `${getDisplayNameHelper(rowValue)}`;
  },
  [ColumnTypes.email]: (rowValue: ProfileView) => {
    return `${defaultEmailFromProfile(rowValue)}`;
  },
  [ColumnTypes.product]: (rowValue: InvoiceItemView[]) => {
    return `${getProductNamesFromInvoiceItems(rowValue)}`;
  },
  [ColumnTypes.membership]: (rowValue: string) => {
    return `${rowValue ?? "Non-Member"}`;
  },
  [ColumnTypes.address]: (
    rowValue: ProfileView,
    property: string,
    use?: string,
  ) => {
    return (
      defaultAddressFromProfile(rowValue, use)?.[
        property as keyof ProfileAddressView
      ]?.toString() ?? ""
    );
  },
  [ColumnTypes.template]: (
    rowValue: string,
    path: string,
    defaultValue: string,
    template: string,
  ) => {
    return renderTemplateUtil({
      template,
      templateData: rowValue,
    });
  },
};
