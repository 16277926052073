import { ComponentPropsWithoutRef } from "react";

type Props = ComponentPropsWithoutRef<"svg">;
const CloudDownloadIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon-tabler icon-tabler-cloud-download ${
        props?.className ?? ""
      } `}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" />
      <line x1="12" y1="13" x2="12" y2="22" />
      <polyline points="9 19 12 22 15 19" />
    </svg>
  );
};

export default CloudDownloadIcon;
