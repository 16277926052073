import { DateTime } from "luxon";
import { ShortcutsItem } from "admin/src/ui/components/common/datepicker/src/types";
type getShortcutsProps = {
  startDay: number;
  startMonth: number;
};

const getShortcuts = (startingTime?: getShortcutsProps) => {
  const curClock = DateTime.now();

  const DEFAULT_SHORTCUTS: {
    [key in string]: ShortcutsItem | ShortcutsItem[];
  } = {
    today: {
      text: "Today",
      period: {
        start: curClock.toISODate(),
        end: curClock.toISODate(),
      },
    },
    yesterday: {
      text: "Yesterday",
      period: {
        start: curClock.minus({ day: 1 }).toISODate(),
        end: curClock.minus({ day: 1 }).toISODate(),
      },
    },
    past: [
      {
        daysNumber: 7,
        text: "Last 7 days",
        period: {
          start: curClock.minus({ day: 7 }).toISODate(),
          end: curClock.toISODate(),
        },
      },
      {
        daysNumber: 30,
        text: "Last 30 days",
        period: {
          start: curClock.minus({ day: 30 }).toISODate(),
          end: curClock.toISODate(),
        },
      },
    ],
    yearToDate: {
      text: "Year-to-Date",
      period: {
        start: DateTime.local(curClock.year, 1, 1).toISODate() ?? "",
        end: curClock.toISODate(),
      },
    },
    monthToDate: {
      text: "Month-to-Date",
      period: {
        start:
          DateTime.local(curClock.year, curClock.month, 1).toISODate() ?? "",
        end: curClock.toISODate(),
      },
    },
    previousWeek: {
      text: "Previous 7 Days",
      period: {
        start: curClock.minus({ day: 7 }).toISODate(),
        end: curClock.toISODate(),
      },
    },
    nextWeek: {
      text: "Next 7 Days",
      period: {
        start: curClock.plus({ day: 1 }).toISODate(),
        end: curClock.plus({ day: 8 }).toISODate(),
      },
    },
    currentYear: {
      text: "Current Year",
      period: {
        start: DateTime.local(curClock.year, 1, 1).toISODate() ?? "",
        end: DateTime.local(curClock.year, 12, 31).toISODate() ?? "",
      },
    },
    last3Years: {
      text: "Last 3 Years",
      period: {
        start: DateTime.local(curClock.year - 3, 1, 1).toISODate() ?? "",
        end: DateTime.local(curClock.year - 1, 12, 31).toISODate() ?? "",
      },
    },
    last5Years: {
      text: "Last 5 Years",
      period: {
        start: DateTime.local(curClock.year - 4, 1, 1).toISODate() ?? "",
        end: DateTime.local(curClock.year, 12, 31).toISODate() ?? "",
      },
    },
    last12Months: {
      text: "Last 12 Months",
      period: {
        start:
          DateTime.local(curClock.year, curClock.month, 1)
            .minus({ months: 12 })
            .toISODate() ?? "",
        end: DateTime.local(curClock.year, curClock.month, 1).toISODate() ?? "",
      },
    },
    last6Months: {
      text: "Last 6 Months",
      period: {
        start:
          DateTime.local(curClock.year, curClock.month, 1)
            .minus({ months: 6 })
            .toISODate() ?? "",
        end: DateTime.local(curClock.year, curClock.month, 1).toISODate() ?? "",
      },
    },
    futureYears: {
      text: "Future Years",
      period: {
        start: DateTime.local(curClock.year + 1, 1, 1).toISODate() ?? "",
        end: new Date(2999, 12, 30),
      },
    },
  };

  if (startingTime) {
    const curBillYear =
      startingTime.startMonth > curClock.month ||
      (startingTime.startMonth === 1 &&
        curClock.month === 1 &&
        startingTime.startDay > curClock.day)
        ? curClock.year - 1
        : curClock.year;
    const curBillMonth =
      startingTime.startDay <= curClock.day
        ? startingTime.startMonth
        : startingTime.startMonth - 1;
    const curBillClock = DateTime.local(curBillYear, curBillMonth, 1);

    const BILLING_SHORTCUTS = {
      previousBillingPeriod: {
        text: "Previous Billing Period",
        period: {
          start: curBillClock.minus({ year: 1 }).toISODate(),
          end: curBillClock.minus({ day: 1 }).toISODate(),
        },
      },
      currentBillingPeriod: {
        text: "Current Billing Period",
        period: {
          start: curBillClock.toISODate(),
          end: curBillClock.plus({ year: 1 }).minus(1).toISODate(),
        },
      },
      nextBillingPeriod: {
        text: "Next Billing Period",
        period: {
          start: curBillClock.plus({ year: 1 }).toISODate(),
          end: curBillClock.plus({ year: 2 }).minus(1).toISODate(),
        },
      },
    };
    Object.assign(DEFAULT_SHORTCUTS, BILLING_SHORTCUTS);
  }
  return DEFAULT_SHORTCUTS;
};
export default getShortcuts;
