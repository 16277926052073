import { isEmpty as lodashIsEmpty } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { PaginatedResults } from "shared/api/types/pagination";
import { ReportResponseType } from "shared/api/types/society/[societyId]/reports/[reportId]";
import { ColumnsView } from "shared/components/pillar-table/custom-reports/displayColumnsMapper";
import { filtersMapper } from "shared/components/pillar-table/custom-reports/filtersMapper";

import PillarTable from "shared/components/pillar-table/PillarTable";
import { CustomReportView } from "shared/mappers/database/reports/report";

interface CustomReportFiltersProps {
  displayColumns: ColumnsView[];
  setDisplayColumns: Dispatch<SetStateAction<ColumnsView[]>>;
  customReportResponse: PaginatedResults<ReportResponseType, CustomReportView>;
}

const CustomReportFilters: React.FC<CustomReportFiltersProps> = ({
  customReportResponse,
  displayColumns,
  setDisplayColumns,
}) => {
  return lodashIsEmpty(customReportResponse?.queryRaw) ? (
    <div className="w-full flex justify-between py-1">
      <PillarTable.Filters>
        <div className="mb-1 flex justify-start items-end w-full gap-x-1">
          {customReportResponse?.filters?.map((item) => {
            const pipe =
              item!.component?.type && filtersMapper[item!.component?.type];
            return pipe && pipe(item);
          })}
        </div>
      </PillarTable.Filters>
      <PillarTable.DisplayColumns
        allColumns={customReportResponse?.displayColumns ?? []}
        displayColumns={displayColumns}
        setDisplayColumns={setDisplayColumns}
      />
    </div>
  ) : null;
};

export default CustomReportFilters;
