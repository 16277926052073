import TemplateComponent from "admin/src/ui/components/TemplateComponent";
import _ from "lodash";
import { createElement, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import type { GroupBase, OptionsOrGroups } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { getSocietyChoicesQuestionNameContract } from "shared/api/contracts/society/societyId/choices/questionName";
import PillarForm from "shared/components/pillar-form/PillarForm";
import { removeHTMLTags } from "shared/components/survey-js-form/utils/removeHTMLTags";
import { SessionView } from "shared/mappers/database/session/session";
import { SocietyCustomChoiceDto } from "shared/mappers/database/society-custom-choices/societyCustomChoice";
import {
  ElementFactory,
  ExpressionValidator,
  Question,
  Serializer,
  settings,
  SurveyModel,
} from "survey-core";
import { SurveyCreatorModel } from "survey-creator-core";
import { SurveyCreator } from "survey-creator-react";
import { ReactQuestionFactory } from "survey-react-ui";

export const CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN = "pillar_choice_dropdown";

export class PillarChoiceDropdownModel extends Question {
  getType() {
    return CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN;
  }
}

(settings as { customIcons: { [index: string]: string } }).customIcons[
  "icon-" + CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN
] = "icon-dropdown";

export type PillarChoiceDropdownInputProps = {
  creator: SurveyCreator;
  isDisplayMode: boolean;
  question: PillarChoiceDropdownModel;
  session: SessionView;
};

export enum PillarChoiceSearchKeyType {
  TEXT_EQUALS = "textEquals",
  VALUE_EQUALS = "valueEquals",
}

export type OptionsValuesPillarChoice = {
  value: number | string;
  label: string;
};

export const PillarChoiceDropdown = (props: PillarChoiceDropdownInputProps) => {
  const {
    pillar_choices_question_name,
    multi_select,
    placeholder_text,
    other_option,
    other_option_text,
    question_value,
    handlebars_template,
    distinct_search_key,
    link_question_values,
    other_placeholder_text,
    min_choices,
    max_choices,
  } = props.question;

  let { search_choices_by_key } = props.question;
  const survey = props.question.survey as SurveyModel;
  const [otherOption, setOtherOption] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [filter, setFilter] = useState<string>(search_choices_by_key);
  const [linkedQuestionValue, setLinkedQuestionValue] = useState<
    OptionsValuesPillarChoice | undefined
  >(undefined);
  const [otherInputValue, setOtherInputValue] = useState<string>("");
  const otherOptionText = removeHTMLTags(other_option_text) ?? "Other";

  survey.onValueChanged.add((sender: SurveyModel) => {
    const linkedQuestion = _.get(
      sender.getAllValues(),
      removeHTMLTags(link_question_values)
    );

    if (!linkedQuestion || linkedQuestion === linkedQuestionValue) {
      return;
    }

    const fetchData = async () => {
      const choices = await fetchCustomChoices(linkedQuestion, []);
      if (choices.options.length > 0) {
        setLinkedQuestionValue(choices.options[0]);
      } else {
        setLinkedQuestionValue(undefined);
      }
    };

    fetchData();
  });

  const handleBarTemplate = (
    <TemplateComponent
      template={removeHTMLTags(handlebars_template)}
      data={{
        value: linkedQuestionValue?.value || "",
        text: linkedQuestionValue?.label || "",
      }}
    />
  );

  useEffect(() => {
    if (linkedQuestionValue && !isSelected) {
      props.question.value = linkedQuestionValue;
    }
  }, [linkedQuestionValue]);

  const fetchCustomChoices = async (
    inputValue: string,
    loadedOptions: OptionsOrGroups<
      OptionsValuesPillarChoice,
      GroupBase<OptionsValuesPillarChoice>
    >,
    page = 1
  ) => {
    const pageSize = 25;

    const choicesResponse = await apiRequestContractHandler(
      getSocietyChoicesQuestionNameContract,
      {
        params: {
          societyId: props.session.societyId ?? 0,
          questionName: pillar_choices_question_name,
          ...(distinct_search_key !== undefined && {
            distinctOn: distinct_search_key,
          }),
        },
      },
      { [filter]: inputValue },
      {
        page,
        pageSize,
      }
    );

    const otherOption: OptionsValuesPillarChoice = {
      value: -1,
      label: otherOptionText,
    };

    const options = choicesResponse.results.map((choices) => {
      const value = question_value
        ? choices[question_value as keyof SocietyCustomChoiceDto]
        : choices.customQuestionChoiceId;

      return {
        value: String(value),
        label: choices.text,
      };
    });
    const hasMore =
      choicesResponse.page < Math.ceil(choicesResponse.totalResults / pageSize);

    return {
      options: other_option ? [...options, otherOption] : options,
      hasMore,
      additional: page + 1,
    };
  };

  if (
    multi_select &&
    Array.isArray(props.question.value) &&
    min_choices > props.question.value?.length &&
    props.question.getType() === CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN
  ) {
    const expressionValidator = new ExpressionValidator();
    expressionValidator.expression = `{${props.question.name}}.length >= ${min_choices}`;
    expressionValidator.text = `You must select at least ${min_choices} choices.`;
    props.question.validators = [expressionValidator];
  } else {
    props.question.validators = [];
  }

  const handleChange = (value: any) => {
    setIsSelected(true);
    if (max_choices && Array.isArray(value) && value.length > max_choices) {
      value.pop();
    }
    const isOther = Array.isArray(value)
      ? value.some((v) => v.label === otherOptionText)
      : value.label === otherOptionText;

    setOtherOption(isOther);
    if (isOther && otherInputValue) {
      if (multi_select) {
        props.question.value = value.map((v: OptionsValuesPillarChoice) =>
          v.label === otherOptionText ? otherInputValue : v.label
        );
      } else {
        props.question.value = otherInputValue;
      }
    } else {
      if (multi_select) {
        props.question.value = value.map(
          (v: OptionsValuesPillarChoice) => v.label
        );
      } else {
        props.question.value = value.label;
      }
    }
  };

  survey.onComplete.add(() => {
    if (multi_select) {
      props.question.value = Array.isArray(props.question.value)
        ? props.question.value.map((v: Question) => v[question_value])
        : [];
    } else {
      props.question.value =
        props.question.value![question_value] ?? props.question.value;
    }
  });

  return (
    <div>
      <AsyncPaginate
        loadOptions={fetchCustomChoices}
        debounceTimeout={500}
        isMulti={multi_select}
        onFocus={() => {
          setFilter("text");
        }}
        onBlur={() => {
          setIsSelected(false);
          setFilter(search_choices_by_key);
        }}
        placeholder={removeHTMLTags(placeholder_text) ?? "Select..."}
        value={
          props.question.value && typeof props.question.value === "object"
            ? {
                value: props.question.value.value,
                label:
                  handlebars_template !== undefined
                    ? removeHTMLTags(renderToString(handleBarTemplate))
                    : props.question.value.label,
              }
            : Array.isArray(props.question.value)
            ? props.question.value.map((v) => ({
                value: v,
                label:
                  handlebars_template !== undefined
                    ? removeHTMLTags(renderToString(handleBarTemplate))
                    : v,
              }))
            : props.question.value
            ? {
                value: props.question.value,
                label:
                  handlebars_template !== undefined
                    ? removeHTMLTags(renderToString(handleBarTemplate))
                    : props.question.value,
              }
            : null
        }
        onChange={handleChange}
        styles={{
          option: (provided) =>
            multi_select
              ? provided
              : {
                  ...provided,
                  backgroundColor: "primary-100",
                  color: "black",
                },
        }}
      />
      {otherOption && (
        <PillarForm
          className="mt-4"
          handleSubmit={async () => {
            //void
          }}
          handleChanged={(values) => {
            multi_select && setOtherInputValue(values.other);
            props.question.value = multi_select
              ? [
                  ...props.question.value.filter(
                    (val: string) => val !== otherOptionText
                  ),
                  values.other,
                ]
              : values.other;
          }}
        >
          <PillarForm.TextInput
            name="other"
            placeholder={removeHTMLTags(other_placeholder_text) ?? "Select..."}
          />
        </PillarForm>
      )}
    </div>
  );
};

export const RegisterPillarChoiceDropdownComponent = (
  session: SessionView,
  creator?: SurveyCreatorModel
) => {
  ElementFactory.Instance.registerElement(
    CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN,
    (name) => {
      return new PillarChoiceDropdownModel(name);
    }
  );

  Serializer.addClass(
    CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN,
    [{ name: "height", default: "200px", category: "layout" }],
    function () {
      return new PillarChoiceDropdownModel("");
    },
    "question"
  );

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "pillar_choices_question_name",
    displayName: "Pillar Choice Category",
    type: "dropdown",
    category: "general",
    choices:
      session.society?.choiceCategory?.map(
        (category) => category.questionName ?? ""
      ) ?? [],
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "placeholder_text",
    displayName: "Placeholder text",
    type: "text",
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "link_question_values",
    displayName: "Link Question Values",
    type: "string",
    category: "Linked Questions",
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "search_choices_by_key",
    displayName: "Search Choices By Key",
    type: "dropdown",
    choices: [
      {
        value: PillarChoiceSearchKeyType.TEXT_EQUALS,
        text: "Text",
      },
      {
        value: PillarChoiceSearchKeyType.VALUE_EQUALS,
        text: "Value",
      },
    ],
    category: "Linked Questions",
    visibleIf: function (obj: Question) {
      return !!obj.link_question_values;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "question_value",
    displayName: "Question Value",
    type: "dropdown",
    choices: [
      {
        value: "label",
        text: "Text",
      },
      {
        value: "value",
        text: "Value",
      },
    ],
    category: "Linked Questions",
    visibleIf: function (obj: Question) {
      return !!obj.link_question_values;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "handlebars_template",
    displayName: "Handlebars Template",
    type: "text",
    category: "Linked Questions",
    visibleIf: function (obj: Question) {
      return !!obj.link_question_values;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "multi_select",
    displayName: "Multi select",
    type: "boolean",
    default: false,
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "min_choices",
    displayName: "Minimum choices for multi select",
    type: "number",
    category: "general",
    visibleIf: function (obj: Question) {
      return obj.multi_select === true;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "max_choices",
    displayName: "Maximum choices for multi select",
    type: "number",
    category: "general",
    visibleIf: function (obj: Question) {
      return obj.multi_select === true;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "other_option",
    displayName: "Other option",
    type: "boolean",
    default: false,
    category: "general",
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "other_option_text",
    displayName: "Other option text",
    type: "text",
    category: "general",
    visibleIf: function (obj: Question) {
      return obj.other_option === true;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "distinct_search_key",
    displayName: "Distinct search key",
    type: "dropdown",
    choices: [
      { value: "text", text: "Text" },
      { value: "value", text: "Value" },
    ],
    category: "Linked Questions",
    visibleIf: function (obj: Question) {
      return !!obj.link_question_values;
    },
  });

  Serializer.addProperty(CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN, {
    name: "other_placeholder_text",
    displayName: "Other placeholder text",
    type: "text",
    category: "general",
    visibleIf: function (obj: Question) {
      return obj.other_option === true;
    },
  });

  ReactQuestionFactory.Instance.registerQuestion(
    CUSTOM_TYPE_PILLAR_CHOICE_DROPDOWN,
    (props: any) => {
      return createElement(PillarChoiceDropdown, {
        ...props,
        session,
      });
    }
  );
};
