import { society_icon } from "@prisma/client";
import { FunctionComponent } from "react";
import BookOutline from "shared/components/icons/book/BookOutline";
import CashOutline from "shared/components/icons/CashOutline";
import ChatBubblesOutline from "shared/components/icons/ChatBubblesOutline";
import EaselOutline from "shared/components/icons/EaselOutline";
import FileTrayFullOutline from "shared/components/icons/file/FileTrayFullOutline";
import FileTrayStackedOutline from "shared/components/icons/file/FileTrayStackedOutline";
import FolderOpenOutline from "shared/components/icons/FolderOpenOutline";
import IdCardOutline from "shared/components/icons/IdCardOutline";
import ListOutline from "shared/components/icons/list/ListOutline";
import MailOutline from "shared/components/icons/mail/MailOutline";
import PeopleOutline from "shared/components/icons/PeopleOutline";
import RibbonOutline from "shared/components/icons/RibbonOutline";
import TodayOutline from "shared/components/icons/TodayOutline";
import { CommonIconProps } from "@parthenon-management/pillar-admin/src/ui/types/icon";

export const hubIconMapper: Record<
  society_icon,
  FunctionComponent<CommonIconProps>
> = {
  [society_icon.bookOutline]: BookOutline,
  [society_icon.cashOutline]: CashOutline,
  [society_icon.chatbubblesOutline]: ChatBubblesOutline,
  [society_icon.easelOutline]: EaselOutline,
  [society_icon.folderOpenOutline]: FolderOpenOutline,
  [society_icon.listOutline]: ListOutline,
  [society_icon.mailOutline]: MailOutline,
  [society_icon.peopleOutline]: PeopleOutline,
  [society_icon.ribbonOutline]: RibbonOutline,
  [society_icon.idCardOutline]: IdCardOutline,
  [society_icon.fileTrayFullOutline]: FileTrayFullOutline,
  [society_icon.todayOutline]: TodayOutline,
  [society_icon.fileTrayStackedOutline]: FileTrayStackedOutline,
};
