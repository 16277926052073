import { SocietyTheme } from "shared/mappers/database/society/theme";
import {
  CustomSessionContext,
  CustomSessionType,
} from "shared/context/CustomSessionContext";
import LoadingPage from "hub/src/components/common/LoadingPage";
import { useEffect, useMemo, useState } from "react";
import { getSocietyId } from "hub/src/utils/getSocietyId";
import { SessionView } from "shared/mappers/database/session/session";
import AblyComponent from "hub/src/components/common/AblyComponent";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import { postSocietyProfileLogoutContract } from "shared/api/contracts/society/societyId/profiles/profileId/logout";
import {
  getSocietyAuthContract,
  postSocietyAuthContract,
} from "shared/api/contracts/society/societyId/auth";
import { SocietyAuthBodyInput } from "shared/api/types/society/[societyId]/auth";
import { getSocietyTokenTokenValueContract } from "shared/api/contracts/society/societyId/token/tokenValue";
import { useCustomRouter } from "shared/context/CustomRouterContext";
import { useHubAbly } from "hub/src/hooks/useHubAbly";
import { useLocation } from "react-router";
import axios from "axios";

export const useHubCustomSession = (): CustomSessionType => {
  //Hooks
  // const history = useHistory();
  const [session, setSession] = useState<SessionView | null>(null);
  const [sessionLoading, setSessionLoading] = useState<boolean>(false);

  const router = useCustomRouter();
  const location = useLocation();
  //ContextState
  const [redirectUrlAfterLogin, setRedirectUrlAfterLogin] = useState<
    string | null
  >(null);
  const [postUrlAfterLogin, setPostUrlAfterLogin] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if ((location.state as any)?.from?.pathname && !redirectUrlAfterLogin) {
      const locationStateFrom = (location.state as any)?.from;
      setRedirectUrlAfterLogin(
        locationStateFrom?.pathname + locationStateFrom?.search,
      );
    }
  }, [location.state]);

  const queryParams = Array.from(
    new URLSearchParams(location.search).entries(),
  ).reduce((acc: Record<string, string | number | boolean>, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
  useEffect(() => {
    if (queryParams.token) {
      setSessionLoading(true);
      tokenCallback(queryParams.token as string);
    }
    if (queryParams.impersonate) {
      setSessionLoading(true);
      tokenCallback(queryParams.impersonate as string);
    }
    if (queryParams.returnUrl) {
      setPostUrlAfterLogin(queryParams.returnUrl as string);
    }
  }, [queryParams.token, queryParams.impersonate, queryParams.returnUrl]);

  const setSocietyTheme = (theme: SocietyTheme[]) => {
    theme.forEach((theme: SocietyTheme) => {
      document.documentElement.style.setProperty(
        `--${theme.property}`,
        theme.value,
      );
    });
  };

  const logoutCallback = async () => {
    setSession(null);
    setSessionLoading(true);
    router.push("/login");
    const response = await apiRequestContractHandler(
      postSocietyProfileLogoutContract,
      {
        params: {
          societyId: getSocietyId(),
          profileId: session!.profileId!,
        },
      },
    );
    await refreshSessionCallback();
  };
  // Remove the call to redirectAfterLoginCallback from refreshSessionCallback
  const refreshSessionCallback = async (tmpRedirectUrlAfterLogin?: string) => {
    try {
      setSessionLoading(true);
      const response = await apiRequestContractHandler(getSocietyAuthContract, {
        params: {
          societyId: getSocietyId(),
        },
      });
      setSession(response);
      await redirectAfterLoginCallback(
        response,
        tmpRedirectUrlAfterLogin ?? redirectUrlAfterLogin,
      );
    } catch (error) {
      console.error(error);
      router.push("/login?redirectUrl=" + location.pathname);
    }
  };

  const [targetPath, setTargetPath] = useState<string | null>(null);

  useEffect(() => {
    if (targetPath && sessionLoading && targetPath === location.pathname) {
      // Navigation is complete when the location matches the target
      setSessionLoading(false);
    }
  }, [location, sessionLoading, targetPath]);
  const redirectAfterLoginCallback = async (
    session?: SessionView | null,
    redirectUrlAfterLogin?: string | null,
  ) => {
    if (session?.societyUserId) {
      if (redirectUrlAfterLogin) {
        router.push(redirectUrlAfterLogin);
        setTargetPath(redirectUrlAfterLogin);
        setRedirectUrlAfterLogin(null);
      } else if (router.pathname === "/" || router.pathname === "/login") {
        setTargetPath("/overview");
        router.push("/overview");
      }
    } else if (session) {
      setSessionLoading(false);
    }
  };

  const loginCallback = async (
    loginDetails: Required<SocietyAuthBodyInput>,
  ) => {
    try {
      setSessionLoading(true);
      const response = await apiRequestContractHandler(
        postSocietyAuthContract,
        {
          params: {
            societyId: getSocietyId(),
          },
          body: loginDetails,
        },
      );
      setSession(response);
      // handler for OIDC POST login interaction
      if (postUrlAfterLogin) {
        await axios.post(
          postUrlAfterLogin,
          {
            accountId: response.societyUserId,
          },
          { withCredentials: true },
        );
        setPostUrlAfterLogin(null);
      } else {
        await redirectAfterLoginCallback(response, redirectUrlAfterLogin);
      }
    } catch (error) {
      setSessionLoading(false);
      throw error;
    }
  };

  const tokenCallback = async (tokenValue: string) => {
    const response = await apiRequestContractHandler(
      getSocietyTokenTokenValueContract,
      {
        params: {
          societyId: getSocietyId()!,
          tokenValue,
        },
      },
    );
    if ("usageLogin" in response) {
      const loginUsage = response.usageLogin!;
      let url = loginUsage.routePath;
      for (const key in loginUsage.pathParam) {
        url = url.replace(`:${key}`, loginUsage.pathParam[key].toString());
      }
      setRedirectUrlAfterLogin(url);
      await refreshSessionCallback(url);
    } else {
      await refreshSessionCallback();
    }
  };

  //Watch Session, sometimes we set it to Null and need to refresh it.
  //I dont know why we need to refresh it, but we did? - GS 11/18/24 6:44PM
  useEffect(() => {
    if (!session && !sessionLoading) {
      refreshSessionCallback();
    }
    if (session?.society && session.society.theme) {
      setSocietyTheme(session.society.theme);
    }
  }, [session, sessionLoading]);

  //The juice of the context
  const value = useMemo(
    () => ({
      setSession,
      logout: logoutCallback,
      login: loginCallback,
      token: tokenCallback,
      refreshSession: refreshSessionCallback,
      environment: session?.environment ?? "prod",
      societyUser: session?.societyUser ?? undefined,
      society: session?.society ?? undefined,
      profile: session?.profile ?? undefined,
      societyAdmin: session?.societyAdmin ?? undefined,
      iat: session?.iat ?? 0,
      authInvalidBefore: session?.authInvalidBefore ?? "",
      societyUserId: session?.societyUserId ?? undefined,
      societyAdminId: session?.societyAdminId ?? undefined,
      adminMode: session?.adminMode ?? false,
      profileId: session?.profileId ?? undefined,
      societyId: session?.societyId ?? undefined,
      societies: session?.societies ?? [],
      profileIds: session?.profileIds ?? [],
      tags: session?.tags ?? [],
      loading: sessionLoading,
      queryParams,
    }),
    [
      logoutCallback,
      loginCallback,
      tokenCallback,
      refreshSessionCallback,
      session?.societyUserId,
      session?.profileId,
      session?.societyAdminId,
      session,
      queryParams,
      sessionLoading,
    ],
  );

  return value;
};

export const UserHubCustomSessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const session = useHubCustomSession();

  const ablyReady = useHubAbly(session);

  if (!session?.societyId) {
    return <LoadingPage />;
  }

  if (ablyReady) {
    return (
      <CustomSessionContext.Provider value={session}>
        <AblyComponent>{children}</AblyComponent>
      </CustomSessionContext.Provider>
    );
  }
  return (
    <CustomSessionContext.Provider value={session}>
      {children}
    </CustomSessionContext.Provider>
  );
};
