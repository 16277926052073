import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { isEmpty as lodashIsEmpty } from "lodash";
import React, { useMemo } from "react";
import {
  PaginatedResults,
  PaginationRequest,
} from "shared/api/types/pagination";
import { ReportResponseType } from "shared/api/types/society/[societyId]/reports/[reportId]";
import PillarTableButtonCellContent from "shared/components/pillar-table/cell-content/PillarTableButtonCellContent";
import { CustomReportDashboardModules } from "shared/components/pillar-table/custom-reports/CustomReportDashboardModules";
import CustomReportFilters from "shared/components/pillar-table/custom-reports/CustomReportFilters";
import CustomReportHeader from "shared/components/pillar-table/custom-reports/CustomReportHeader";
import PillarTableCheckboxColumn from "shared/components/pillar-table/display-columns/PillarTableCheckboxColumn";
import PillarTableDateTimeColumn from "shared/components/pillar-table/display-columns/PillarTableDateTimeColumn";
import PillarTableDisplayColumns from "shared/components/pillar-table/display-columns/PillarTableDisplayColumns";
import PillarTablePossibleActionsColumn from "shared/components/pillar-table/display-columns/PillarTablePossibleActionsColumn";
import PillarTableStatusColumn from "shared/components/pillar-table/display-columns/PillarTableStatusColumn";
import PillarTableTemplateColumn from "shared/components/pillar-table/display-columns/PillarTableTemplateColumn";
import PillarTableUSDColumn from "shared/components/pillar-table/display-columns/PillarTableUSDColumn";
import { usePillarTableColumnsValues } from "shared/components/pillar-table/display-columns/usePillarTableColumnsValues";
import PillarTableCellEdit from "shared/components/pillar-table/edit/PillarTableCellEdit";
import PillarTableCellEditSelectMenu from "shared/components/pillar-table/edit/PillarTableCellEditSelectMenu";
import { AppExportToExcelBook } from "shared/components/pillar-table/export/advanced/AppExportToExcelBook";
import PillarTableExportToExcelDynamicColumn from "shared/components/pillar-table/export/advanced/AppExportToExcelDynamicColumn";
import { AppExportToExcelSheet } from "shared/components/pillar-table/export/advanced/AppExportToExcelSheet";
import { AppMultipleExportToExcel } from "shared/components/pillar-table/export/advanced/AppMultipleExportToExcel";
import PillarTableExportToExcel from "shared/components/pillar-table/export/PillarTableExportToExcel";
import PillarTableExportToExcelColumn from "shared/components/pillar-table/export/PillarTableExportToExcelColumn";
import PillarTableFilterCheckbox from "shared/components/pillar-table/filters/PillarTableFilterCheckbox";
import PillarTableFilterDatePicker from "shared/components/pillar-table/filters/PillarTableFilterDatePicker";
import PillarTableFilterHidden from "shared/components/pillar-table/filters/PillarTableFilterHidden";
import PillarTableFilters from "shared/components/pillar-table/filters/PillarTableFilters";
import PillarTableFilterSelectMenu from "shared/components/pillar-table/filters/PillarTableFilterSelectMenu";
import PillarTableFilterSelectMenuCheckbox from "shared/components/pillar-table/filters/PillarTableFilterSelectMenuCheckbox";
import PillarTableFilterSingleDate from "shared/components/pillar-table/filters/PillarTableFilterSingleDate";
import PillarTableFilterTextInput from "shared/components/pillar-table/filters/PillarTableFilterTextInput";
import PillarTableFilterTreeSelectMenuCheckbox from "shared/components/pillar-table/filters/PillarTableFilterTreeSelectMenuCheckbox";
import { usePillarTableFilters } from "shared/components/pillar-table/filters/usePillarTableFilters";
import PillarTableHeaderBar from "shared/components/pillar-table/header-bar/PillarTableHeaderBar";
import PillarTableNestedTable from "shared/components/pillar-table/nested/PillarTableNestedTable";
import PillarTablePagination from "shared/components/pillar-table/pagination-sorting/PillarTablePagination";
import usePillarTablePaginationSorting from "shared/components/pillar-table/pagination-sorting/usePillarTablePaginationSorting";
import PillarTableBody from "shared/components/pillar-table/PillarTableBody";
import PillarTableColumn from "shared/components/pillar-table/PillarTableColumn";
import PillarTableHeader from "shared/components/pillar-table/PillarTableHeader";
import PillarTablePopoverMenu, {
  PillarTableSubmenuItem,
} from "shared/components/pillar-table/PillarTablePopoverMenu";
import { PillarTableQueryContext } from "shared/components/pillar-table/query/PillarTableQueryContext";
import { usePillarTableQueryValues } from "shared/components/pillar-table/query/usePillarTableQueryValues";
import { createComponentWrapper } from "shared/components/pillar-table/wrappers/createComponentWrapper";
import { CustomReportView } from "shared/mappers/database/reports/report";

export type StaticTableData<T extends object> = { data: T[] };

export type UseQueryRefetch = (
  refetchFn: (options?: RefetchOptions) => Promise<QueryObserverResult>
) => ReturnType<typeof refetchFn>;

export type QueryTableData<T extends object, ExtraProps = any> = (
  filters: Record<string, any>,
  paginationRequest?: PaginationRequest
) => Promise<PaginatedResults<T, ExtraProps>> | Promise<T[]>;

//TODO: After discussion, we may want to refactor this eventually to fix whatever issue is causing the re-render problem
//and allow it to take an async function that isn't attached to our axios callouts, but for speed to green, this works and is
//being left in for now.
//We use a dual typing for dataQuery in order to allow either a static data array or a dynamic asynchronous query call
//The reason we do not use two different properties is typescript does not play nice with inference of undefined properties
//and we don't want to have to always provide both properties and cast the undefined one to make typescript happy. In addition,
//there were attempts made to just pass in an asynchronous method that just returned the static data without axios or any other
//callout, but that produce a lot of unexpected bugs, whereas this method did not.
type PillarTableProps<T extends object> = {
  testid?: string;
  children: React.ReactNode;
  emptyTableMessage?: string;
  queryKey: string | string[];
  pagination?: boolean;
  dataQuery: QueryTableData<T> | StaticTableData<T>;
  checkboxColumnFormValueProperty?: boolean | string;
  checkboxColumnRowValueProperty?: boolean | string;
  centered?: boolean;
  exportToExcel?: boolean;
  urlParams?: boolean;
  wrapperClassName?: string;
  menu?:
    | PillarTableSubmenuItem<T>[]
    | ((row: T) => PillarTableSubmenuItem<T>[]);
  handleRowClick?: (row: T) => void;
  handleInlineEdit?: (row: T) => Promise<boolean>;
  handleInlineDelete?: (row: T) => Promise<boolean>;
  refetchData?: UseQueryRefetch;
  multiRowPropertyKeys?: string[];
} & React.ComponentPropsWithoutRef<"div">;

const PillarTable = <T extends object, ExtraPropsT = Record<string, never>>({
  testid: testidTable,
  emptyTableMessage,
  pagination = true,
  children,
  dataQuery,
  queryKey,
  urlParams,
  handleRowClick,
  handleInlineEdit,
  handleInlineDelete,
  refetchData,
  checkboxColumnFormValueProperty = false,
  checkboxColumnRowValueProperty = false,
  wrapperClassName,
  className,
  menu,
  multiRowPropertyKeys,
  ...props
}: PillarTableProps<T>) => {
  const { filterComponentWrapper, filters, filtersRendered, setFilters } =
    usePillarTableFilters({
      children,
      urlParams:false,
      queryKey,
    });

  const newPillarTableNestedTableComponentWrapper = createComponentWrapper(
    PillarTableNestedTable,
    children
  );

  const HeaderBarComponentWrapper = createComponentWrapper(
    PillarTableHeaderBar,
    children
  );

  const { paginationState, setPaginationState, toggleSorting } =
    usePillarTablePaginationSorting<T>({
      filters,
      pagination,
      urlParams:false,
    });

  const {
    loadedFirstTime,
    tableRowResults,
    tableQuery,
    dynamicFilterChoices,
    totalResults,
    extraProps,
  } = usePillarTableQueryValues<T, ExtraPropsT>({
    dataQuery,
    queryKey,
    filters,
    filtersRendered,
    paginationState,
    refetchData,
  });

  const customReportResponse = tableQuery?.data as PaginatedResults<
    ReportResponseType,
    CustomReportView
  >;

  const {
    fixedColumnWidths,
    columnRefs,
    columns,
    displayColumns,
    setDisplayColumns,
  } = usePillarTableColumnsValues({
    children: React.Children.toArray(children),
    checkboxColumnFormValueProperty,
    checkboxColumnRowValueProperty,
    pagination,
    tableQuery,
  });

  const memoizedValues = useMemo(
    () => ({
      filters,
      queryKey,
      dataQuery,
      multiRowPropertyKeys,
    }),
    [filters, queryKey, dataQuery, multiRowPropertyKeys]
  );

  return (
    <div
      className={`flex flex-col justify-start items-start w-full ${
        wrapperClassName ?? ""
      }`}
      {...props}
    >
      {!lodashIsEmpty(customReportResponse?.dashboardModules) && (
        <CustomReportDashboardModules
          customReportResponse={customReportResponse}
        />
      )}

      <PillarTableQueryContext.Provider
        value={{
          tableRowResults,
          tableQuery,
          dynamicFilterChoices,
          totalResults,
          extraProps,
          columns,
          filters: memoizedValues.filters,
          queryKey: memoizedValues.queryKey,
          setFilters,
          refetchData,
          dataQuery: memoizedValues.dataQuery,
          multiRowPropertyKeys: memoizedValues.multiRowPropertyKeys,
        }}
      >
        <HeaderBarComponentWrapper />

        {filterComponentWrapper && <>{filterComponentWrapper}</>}

        <CustomReportHeader customReportResponse={customReportResponse} />

        <div className={`overflow-x-auto w-full ${className ?? ""}`}>
          <CustomReportFilters
            displayColumns={displayColumns}
            setDisplayColumns={setDisplayColumns}
            customReportResponse={customReportResponse}
          />
          <table
            data-testid={testidTable ? `${testidTable}-table` : undefined}
            className={`${
              pagination
                ? "pillartable-table-base"
                : "pillartable-table-base-roundbottom"
            } w-full`}
          >
            <PillarTableHeader
              paginationState={paginationState}
              toggleSorting={toggleSorting}
              fixedColumnWidths={fixedColumnWidths}
              testidTable={testidTable}
              handleInlineEdit={handleInlineEdit}
              handleInlineDelete={handleInlineDelete}
              menu={menu}
              columnRefs={columnRefs}
            />

            <PillarTableBody
              testidTable={testidTable}
              handleRowClick={handleRowClick}
              handleInlineEdit={handleInlineEdit}
              handleInlineDelete={handleInlineDelete}
              menu={menu}
              fixedColumnWidths={fixedColumnWidths}
              refetchData={tableQuery.refetch}
              emptyTableMessage={emptyTableMessage}
              loadedFirstTime={loadedFirstTime}
              newPillarTableNestedTableComponentWrapper={
                newPillarTableNestedTableComponentWrapper() || undefined
              }
            />
          </table>
        </div>
        {pagination &&
          tableRowResults &&
          dataQuery instanceof Function &&
          (!customReportResponse?.queryRaw ||
            lodashIsEmpty(customReportResponse.queryRaw)) && (
            <PillarTablePagination
              testid={testidTable}
              results={totalResults}
              paginationState={paginationState}
              handler={(newPagination) =>
                setPaginationState({ ...paginationState, ...newPagination })
              }
            />
          )}
      </PillarTableQueryContext.Provider>
    </div>
  );
};

PillarTable.Column = PillarTableColumn;
PillarTable.DisplayColumns = PillarTableDisplayColumns;
PillarTable.CheckboxColumn = PillarTableCheckboxColumn;
PillarTable.TemplateColumn = PillarTableTemplateColumn;
PillarTable.DateTimeColumn = PillarTableDateTimeColumn;
PillarTable.USDColumn = PillarTableUSDColumn;
PillarTable.PossibleActionsColumn = PillarTablePossibleActionsColumn;
PillarTable.PossibleActionsColumn = PillarTablePossibleActionsColumn;
PillarTable.StatusColumn = PillarTableStatusColumn;

PillarTable.Button = PillarTableButtonCellContent;

PillarTable.PopoverMenu = PillarTablePopoverMenu;
PillarTable.NestedTable = PillarTableNestedTable;
PillarTable.HeaderBar = PillarTableHeaderBar;
PillarTable.Filters = PillarTableFilters;
PillarTable.FilterCheckbox = PillarTableFilterCheckbox;
PillarTable.FilterDatePicker = PillarTableFilterDatePicker;
PillarTable.FilterHidden = PillarTableFilterHidden;
PillarTable.FilterSelectMenuCheckbox = PillarTableFilterSelectMenuCheckbox;
PillarTable.FilterSelectMenu = PillarTableFilterSelectMenu;
PillarTable.FilterSingleDate = PillarTableFilterSingleDate;
PillarTable.FilterTextInput = PillarTableFilterTextInput;
PillarTable.FilterTreeSelectMenuCheckbox =
  PillarTableFilterTreeSelectMenuCheckbox;
PillarTable.ExportToExcelColumn = PillarTableExportToExcelColumn;
PillarTable.ExportToExcel = PillarTableExportToExcel;
PillarTable.MultipleExportToExcel = AppMultipleExportToExcel;
PillarTable.ExportToExcelSheet = AppExportToExcelSheet;
PillarTable.ExportToExcelDynamicColumn = PillarTableExportToExcelDynamicColumn;
PillarTable.ExportToExcelBook = AppExportToExcelBook;
PillarTable.CellEditSelectMenu = PillarTableCellEditSelectMenu;
PillarTable.CellEdit = PillarTableCellEdit;

export default PillarTable;
