import { dashboard_module_type } from "@prisma/client";
import { Fragment } from "react";
import { PaginatedResults } from "shared/api/types/pagination";
import { ReportResponseType } from "shared/api/types/society/[societyId]/reports/[reportId]";
import { dashboardMapper } from "shared/components/dashboard/dashboardMapper";
import { CustomReportView } from "shared/mappers/database/reports/report";

export const CustomReportDashboardModules = ({
  customReportResponse,
}: {
  customReportResponse: PaginatedResults<ReportResponseType, CustomReportView>;
}) => {
  return (
    <div
      className={`grid pb-6 grid-cols-${
        customReportResponse.dashboardModules?.length ?? 0
      }`}
    >
      {customReportResponse.dashboardModules?.map((module) => {
        if (!module.type) return <Fragment key={module.id} />;
        const pipe = dashboardMapper[module.type];

        return (
          <div
            key={module.id}
            className={`m-2 col-span-${module.colSpan} col-start-${
              module.positionColumn
            } 
            ${
              module.type !== dashboard_module_type.total
                ? "row-start-1"
                : "row-start-2"
            } ${module.classNames}`}
          >
            {pipe(module.id, customReportResponse.societyId!)}
          </div>
        );
      })}
    </div>
  );
};
