import { PrismaClient } from "@prisma/client";
import { get, set, update } from "lodash";
import { DateTime } from "luxon";
import { mapGroupByResponseToPaginationFilter } from "shared/mappers/database/mapGroupByResponseToPaginationFilter";
export interface FilterChoice {
  value: number | string;
  display: string;
  count?: number;
  choices?: FilterChoice[];
  filterKey?: string;
}

export interface AppFilterOption {
  filterKey?: string;
  component?: FilterOptionComponent;
  where?: FilterOptionWhereConfig[];
  choices?: FilterChoice[];
  dynamicChoiceOptions?: DynamicFilterChoiceOptions;
}
export type DynamicFilterChoiceOptions = {
  tableNameForGroupBy?: ModelsWithGroupBy;
  tableNameForFindMany?: ModelsWithGroupBy;
  fieldForFindMany?: string;
};

export interface FilterOptionComponent {
  label?: string;
  type?: keyof typeof FilterComponentType;
}

export interface FilterOptionWhereConfig {
  type: FilterType;
  path: string;
  arrayPath?: string;
  nonePath?: string;
  copy?: string | Record<string, any>;
  copyPath?: string;
}

export interface FilterOptionsDateRange {
  type: FilterType.DATE_RANGE;
  "key-start": string;
  "key-end": string;
  "path-end": string;
  "path-start": string;
  default?: string;
}

export type FilterRequestTypes =
  | string
  | string[]
  | number
  | number[]
  | undefined
  | boolean;
export interface FiltersRequest {
  [key: string]: FilterRequestTypes;
}

export enum FilterType {
  JSON = "JSON",
  JSON_ARRAY = "JSON_ARRAY",
  INT_ARRAY = "INT_ARRAY",
  STRING = "STRING",
  NUMBER = "NUMBER",
  STRING_ARRAY = "STRING_ARRAY",
  DATE = "DATE",
  DATE_ARRAY = "DATE_ARRAY",
  BOOLEAN = "BOOLEAN",
  BOOLEAN_SPECIAL = "BOOLEAN_SPECIAL",
  NOT_NULL = "NOT_NULL",
  DATE_RANGE = "DATE_RANGE",
}

export enum FilterComponentType {
  none = "none",
  search = "search",
  Date = "Date",
  selectMenuCheckbox = "selectMenuCheckbox",
  selectMenu = "selectMenu",
  checkbox = "checkbox",
}

export type ModelsWithGroupBy = {
  [K in keyof PrismaClient]: PrismaClient[K] extends {
    groupBy: (...args: any[]) => any;
  }
    ? K
    : never;
}[keyof PrismaClient];

const handleArrayFilter = (
  filterValue: string[] | number[],
  filter: FilterOptionWhereConfig,
  whereClause: { [key: string]: any },
) => {
  if (filter.copy) {
    const copyPath = filter.copy as string;
    const conditions = filterValue.map((value) => set({}, copyPath, value));
    if (conditions.length) {
      const existingConditions = get(whereClause, filter.path, []);
      set(whereClause, filter.path, [...existingConditions, ...conditions]);
    }
  } else {
    set(whereClause, filter.path, filterValue);
  }
};

const handleNotNullFilter = (
  filterValue: string,
  path: string,
  whereClause: { [key: string]: any },
) => {
  if (filterValue === "true") {
    set(whereClause, path, { not: { equals: null } });
  }
  if (filterValue === "false") {
    set(whereClause, path, { equals: null });
  }
};

const handleJSONFilter = (
  filterValue: string | number | boolean | Date | string[] | number[],
  filter: FilterOptionWhereConfig,
  whereClause: { [key: string]: any },
) => {
  if (filter.type === FilterType.JSON_ARRAY) {
    (filterValue as string[]).forEach((value) => {
      const jsonQuery = JSON.parse(JSON.stringify(filter.copy));
      set(jsonQuery, filter.copyPath!, value);
      const existingConditions = get(whereClause, filter.path, []);
      set(whereClause, filter.path, [...existingConditions, jsonQuery]);
    });
  } else if (filter.type === FilterType.JSON) {
    const jsonQuery = filter.copy as object;
    set(jsonQuery, filter.copyPath!, filterValue);
    const existingConditions = get(whereClause, filter.path);
    if (existingConditions && Array.isArray(existingConditions)) {
      set(whereClause, filter.path, [...existingConditions, jsonQuery]);
    } else if (existingConditions) {
      set(whereClause, filter.path, { ...existingConditions, ...jsonQuery });
    } else {
      set(whereClause, filter.path, jsonQuery);
    }
  }
};

export const mappedFilterWhereClauseValues = (
  config: AppFilterOption[],
  requestFilters: FiltersRequest = {},
) => {
  const whereClause: { [key: string]: any } = {};

  if (!config || !Array.isArray(config)) {
    return whereClause;
  }

  for (const filter of config) {
    if (!filter.where || !filter.filterKey) {
      continue;
    }
    const filterValue = requestFilters[filter.filterKey] as
      | string
      | string[]
      | number
      | number[]
      | Date
      | undefined
      | boolean;

    if (filterValue !== undefined) {
      filter.where.forEach((filterWhere) => {
        switch (filterWhere.type) {
          case FilterType.NOT_NULL: {
            handleNotNullFilter(
              filterValue as string,
              filterWhere.path,
              whereClause,
            );
            break;
          }
          case FilterType.INT_ARRAY: {
            let processedArray = (filterValue as string[])
              .map((val) => parseInt(val));
            if ((filterValue as string[]).includes("none")) {
              set(whereClause, filterWhere.nonePath ?? filterWhere.path, null)
              handleArrayFilter(
                processedArray.filter((val) => !isNaN(val)),
                filterWhere,
                whereClause,
              );
            } else {
              handleArrayFilter(
                processedArray.filter((val) => !isNaN(val)),
                filterWhere,
                whereClause,
              );
            }
            break;
          }
          case FilterType.STRING_ARRAY: {
            handleArrayFilter(
              filterValue as string[],
              filterWhere,
              whereClause,
            );
            break;
          }
          case FilterType.BOOLEAN: {
            set(whereClause, filterWhere.path, filterValue === "true");
            break;
          }
          case FilterType.DATE_ARRAY:
          case FilterType.DATE: {
            if (typeof filterValue === "string") {
              let processedDate = DateTime.fromISO(filterValue); //treats the ISO date as local central time because "America/Chicago"
              if (filterWhere.path.endsWith("lte")) {
                processedDate = processedDate.endOf("day");
              } else if (filterWhere.path.endsWith("gte")) {
                processedDate = processedDate.startOf("day");
              }
              if (filterWhere.type === FilterType.DATE_ARRAY) {
                const arrayPath = filterWhere.path!;
                const tempObject = {};
                const stringFilterValue = processedDate.toISO(); //Comes out as UTC time
                set(tempObject, filterWhere.path, stringFilterValue);
                update(whereClause, arrayPath, (arr = []) => [
                  ...arr,
                  tempObject,
                ]);
              } else {
                set(whereClause, filterWhere.path, processedDate.toISO()); //Comes out as UTC time
              }
            }
            break;
          }
          case FilterType.JSON_ARRAY:
          case FilterType.JSON: {
            handleJSONFilter(filterValue, filterWhere, whereClause);
            break;
          }
          case FilterType.NUMBER: {
            set(whereClause, filterWhere.path, Number(filterValue));
            break;
          }
          case FilterType.BOOLEAN_SPECIAL: {
            break;
          }
          default: {
            set(whereClause, filterWhere.path, filterValue);
            break;
          }
        }
      });
    }
  }

  return removeEmptyItemsFromArraysRecursively(whereClause);
};

const removeEmptyItemsFromArraysRecursively = (obj: any) => {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((item: any) => {
        if (typeof item === "object") {
          removeEmptyItemsFromArraysRecursively(item);
          return Object.keys(item).length > 0;
        }
        return item !== undefined;
      });
    }
  }
  return obj;
};

export default mappedFilterWhereClauseValues;
