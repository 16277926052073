// shared/components/pillar-table/pagination-sorting/usePillarTablePaginationSorting.tsx

import { useCustomRouter } from "shared/context/CustomRouterContext";
import { PaginationRequest } from "shared/api/types/pagination";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { defaultPageSize } from "shared/components/pillar-table/pagination-sorting/PillarTablePagination";
import { OrderTypes } from "shared/components/pillar-table/PillarTableHeaderCell";

export type SortHandlerProps = {
  orderBy?: string;
  orderType?: string;
};

interface UsePillarTablePaginationSortingProps<T extends object> {
  filters: Record<string, any>;
  pagination?: boolean;
  urlParams?: boolean;
}

const paginationFromUrl = (
  router: ReturnType<typeof useCustomRouter>,
  urlParams?: boolean,
): PaginationRequest => {
  if (!urlParams) {
    return {
      page: 1,
      pageSize: defaultPageSize,
    };
  }
  const query = queryString.parseUrl(router.asPath, {
    arrayFormat: "bracket",
  });
  const page = query.query.page ? Number(query.query.page) : 1;
  const pageSize = query.query.pageSize
    ? Number(query.query.pageSize)
    : defaultPageSize;

  const getFirstValue = (param: unknown) =>
    Array.isArray(param) ? param[0] : param;

  const orderBy = getFirstValue(query.query.orderBy) ?? undefined;
  const orderType = getFirstValue(query.query.orderType) ?? undefined;

  return {
    page,
    pageSize,
    orderBy,
    orderType,
  };
};

export const usePillarTablePaginationSorting = <T extends object>({
  filters,
  pagination,
  urlParams,
}: UsePillarTablePaginationSortingProps<T>) => {
  const router = useCustomRouter();

  const [paginationState, setPaginationState] = useState<
    PaginationRequest | undefined
  >(pagination ? paginationFromUrl(router, urlParams) : undefined);

  const handleSort = ({ orderBy, orderType }: SortHandlerProps) => {
    setPaginationState((prevState) => ({
      page: prevState!.page,
      pageSize: prevState!.pageSize,
      orderBy,
      orderType,
    }));
  };

  const toggleSorting = (fieldName: string) => {
    if (paginationState?.orderBy && paginationState.orderBy !== fieldName) {
      handleSort({ orderBy: fieldName, orderType: OrderTypes.desc });
      return;
    }

    if (!paginationState?.orderType) {
      handleSort({ orderBy: fieldName, orderType: OrderTypes.desc });
    } else if (paginationState.orderType === OrderTypes.desc) {
      handleSort({ orderBy: fieldName, orderType: OrderTypes.asc });
    } else {
      handleSort({ orderBy: undefined, orderType: undefined });
    }
  };

  // Update URL parameters when pagination state or filters change.
  useEffect(() => {
    if (urlParams) {
      const query = queryString.parseUrl(router.asPath, {
        arrayFormat: "bracket",
      });
      const string = queryString.stringify(
        {
          ...query.query,
          page: pagination ? paginationState!.page : undefined,
          pageSize: pagination ? paginationState!.pageSize : undefined,
          orderBy: pagination ? paginationState!.orderBy : undefined,
          orderType:
            pagination && paginationState!.orderBy
              ? paginationState!.orderType
              : undefined,
          ...filters,
        },
        {
          skipEmptyString: true,
          skipNull: true,
          arrayFormat: "bracket",
        },
      );
      router.replace(`${query.url}?${string}`);
    }
  }, [paginationState, filters]);

  return {
    paginationState,
    setPaginationState,
    toggleSorting,
  };
};

export default usePillarTablePaginationSorting;
