import { ComponentPropsWithoutRef } from "react";
import { twMerge } from "tailwind-merge";

// https://tabler.io/icons/icon/dots-vertical
const DotsVertical = ({
  className,
  ...props
}: ComponentPropsWithoutRef<"svg">) => {
  const defaultClassnames =
    "icon icon-tabler icon-tabler-dots-vertical h-3 w-3";

  return (
    <svg
      data-testid="icon-dots-vertical"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(defaultClassnames, className)}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="12" r="1" />
      <circle cx="12" cy="19" r="1" />
      <circle cx="12" cy="5" r="1" />
    </svg>
  );
};

export default DotsVertical;
