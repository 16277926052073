import { Menu, Transition } from "@headlessui/react";
import React, { ComponentPropsWithoutRef, Fragment } from "react";
import DotsVertical from "shared/components/icons/dot/DotsVertical";

export type AppMenuItem = {
  icon?: React.ReactNode;
  label: string;
  onSelect?: () => void;
  itemClassName?: string;
};

type MenuComponentProps = ComponentPropsWithoutRef<"div"> & {
  buttonIcon?: React.ReactNode;
  overrideClassName?: string;
  wrapperClassName?: string;
  menuItems: AppMenuItem[];
  testid?: string;
};

const MenuComponent = ({
  buttonIcon,
  menuItems,
  className,
  overrideClassName,
  wrapperClassName,
  testid = "more-options",
  ...props
}: MenuComponentProps) => {
  return (
    <div
      className={`relative inline-block ${wrapperClassName ?? ""}`}
      data-testid={testid}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
      }}
    >
      <Menu
        as="div"
        className={` ${
          overrideClassName ||
          `inline-block justify-end h-8 text-left ${className ?? ""}`
        }`}
        {...props}
      >
        <Menu.Button className="button-regular-neutral-unfilled pt-0 hover:cursor-pointer">
          {buttonIcon || (
            <DotsVertical className="text-neutral-mid-550 w-2 h-2" />
          )}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="popovermenu-container mt-2.5 flex-flex-col min-w-15 absolute right-0 z-10 origin-bottom-right">
            {menuItems.map((item, index) => (
              <Menu.Item key={index}>
                {() => (
                  <div
                    className={`popovermenu-item flex gap-0.5 items-center pt-1 pb-1 first:pt-0 last:pb-0 ${
                      item?.itemClassName ?? ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      item?.onSelect && item.onSelect();
                    }}
                  >
                    <span>{item?.icon}</span>
                    <span>{item.label}</span>
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default MenuComponent;
