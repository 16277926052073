import { Disclosure } from "@headlessui/react";
import React, { FunctionComponent, useState } from "react";
import { CommonIconProps } from "@parthenon-management/pillar-admin/src/ui/types/icon";
import ChevronDownIcon from "../icons/ChevronDownIcon";

export type MenuItemDisclosureProps = {
  label: string;
  initialIsOpen: boolean;
  children: React.ReactNode;
  panelClassName?: string;
  buttonClassName?: string;
  chevronIconClassname?: string;
  className?: string;
  IconComponent?: FunctionComponent<CommonIconProps>;
};

const MenuItemDisclosure = ({
  label,
  initialIsOpen = false,
  children,
  buttonClassName,
  panelClassName,
  chevronIconClassname,
  className,
  IconComponent,
}: MenuItemDisclosureProps) => {
  const [panelIsOpen, setPanelIsOpen] = useState<boolean>(initialIsOpen);

  return (
    <Disclosure as="div" className={className}>
      {() => (
        <>
          <Disclosure.Button
            className={`flex w-full justify-between px-2 py-2 text-left text-sm font-medium text-neutral-mid ${buttonClassName}`}
            onClick={() => setPanelIsOpen((prev) => !prev)}
          >
            <div className="flex">
              {IconComponent && (
                <IconComponent className="sidebar-menu-svg ion-like-icon" />
              )}
              <span>{label}</span>
            </div>
            <ChevronDownIcon
              className={`${
                panelIsOpen ? "rotate-180 transform" : ""
              } ${chevronIconClassname}`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className={panelClassName}>
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default MenuItemDisclosure;
