import React from "react";
import { ColumnsView } from "shared/components/pillar-table/custom-reports/displayColumnsMapper";
import SelectMenuCheckbox from "admin/src/ui/components/common/SelectMenuCheckbox";
const mapCamelCaseToNormal = (camelCaseString: string): string => {
  const words = camelCaseString.split(/(?=[A-Z])/);
  const normalString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return normalString;
};

const removeColumn = (columnName: string, columns: ColumnsView[]) =>
  columns.filter((col) => col.name !== columnName);

const isColumnSelected = (columnName: string, columns: ColumnsView[]) =>
  columns.some((col) => col.name === columnName);

const addColumn = (
  columnName: string,
  allColumns: ColumnsView[],
  currentColumns: ColumnsView[],
) => {
  const columnToAdd = allColumns.find((col) => col.name === columnName);
  if (!columnToAdd) return currentColumns;

  const newColumnIndex = allColumns.findIndex((col) => col.name === columnName);
  const updatedColumns = [...currentColumns];
  updatedColumns.splice(newColumnIndex, 0, columnToAdd);
  return updatedColumns;
};

type PillarFormTableDisplayColumnsProps = {
  allColumns: ColumnsView[];
  displayColumns: ColumnsView[];
  setDisplayColumns: React.Dispatch<React.SetStateAction<ColumnsView[]>>;
};

const PillarTableDisplayColumns = ({
  allColumns,
  displayColumns,
  setDisplayColumns,
}: PillarFormTableDisplayColumnsProps) => {
  return (
    <SelectMenuCheckbox
      title="Display columns"
      staticItems={allColumns.map((item) => ({
        displayName: mapCamelCaseToNormal(item.name),
        value: item.name,
      }))}
      dynamicItems={displayColumns.map((item) => ({
        displayName: mapCamelCaseToNormal(item.name),
        value: item.name,
      }))}
      onSelect={(item) => {
        setDisplayColumns((prevColumns) => {
          if (isColumnSelected(item.value, prevColumns)) {
            return removeColumn(item.value, prevColumns);
          }
          return addColumn(item.value, allColumns, prevColumns);
        });
      }}
    />
  );
};

export default PillarTableDisplayColumns;
