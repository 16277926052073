/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from "dayjs";
import React, { createContext } from "react";
import {
  LANGUAGE,
  DATE_FORMAT,
  START_WEEK,
} from "admin/src/ui/components/common/datepicker/src/constants";
import {
  Configs,
  Period,
  DateValueType,
  DateType,
  DateRangeType,
  ClassNamesTypeProp,
  PopoverDirectionType,
} from "admin/src/ui/components/common/datepicker/src/types";
interface DatepickerStore {
  input?: React.RefObject<HTMLInputElement>;
  asSingle?: boolean;
  configs?: Configs;
  calendarContainer: React.RefObject<HTMLDivElement> | null;
  calendarContainerShadow: React.RefObject<HTMLDivElement> | null;
  hideDatepicker: () => void;
  period: Period;
  changePeriod: (period: Period) => void;
  dayHover: string | null;
  changeDayHover: (day: string | null) => void;
  inputText: string;
  changeInputText: (text: string) => void;
  updateFirstDate: (date: dayjs.Dayjs) => void;
  changeDatepickerValue: (
    value: DateValueType,
    e?: HTMLInputElement | null | undefined,
  ) => void;
  showFooter?: boolean;
  placeholder?: string | null;
  separator: string;
  i18n: string;
  value: DateValueType;
  disabled?: boolean;
  inputClassName?: ((className: string) => string) | string | null;
  containerClassName?: ((className: string) => string) | string | null;
  toggleClassName?: ((className: string) => string) | string | null;
  toggleIcon?: (open: boolean) => React.ReactNode;
  readOnly?: boolean;
  startWeekOn?: string | null;
  displayFormat: string;
  minDate?: DateType | null;
  maxDate?: DateType | null;
  dateLooking?: "forward" | "backward" | "middle";
  disabledDates?: DateRangeType[] | null;
  inputId?: string;
  inputName?: string;
  classNames?: ClassNamesTypeProp;
  popoverDirection?: PopoverDirectionType;
}

export const DatepickerContext = createContext<DatepickerStore>({
  input: undefined,
  configs: undefined,
  calendarContainer: null,
  calendarContainerShadow: null,
  period: { start: null, end: null },
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  changePeriod: (period) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideDatepicker: () => {},
  dayHover: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  changeDayHover: (day: string | null) => {},
  inputText: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  changeInputText: (text) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  updateFirstDate: (date) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  changeDatepickerValue: (
    value: DateValueType,
    e: HTMLInputElement | null | undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
  showFooter: false,
  value: null,
  i18n: LANGUAGE,
  disabled: false,
  inputClassName: "",
  containerClassName: "",
  toggleClassName: "",
  readOnly: false,
  displayFormat: DATE_FORMAT,
  minDate: null,
  maxDate: null,
  dateLooking: "forward",
  disabledDates: null,
  inputId: undefined,
  inputName: undefined,
  startWeekOn: START_WEEK,
  toggleIcon: undefined,
  classNames: undefined,
  popoverDirection: undefined,
  separator: "~",
});

export default DatepickerContext;
