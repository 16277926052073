import {
  CustomRouterContext,
  CustomRouterType,
} from "shared/context/CustomRouterContext";
import { generatePath, useHistory, useLocation } from "react-router";
import { RouteParams } from "shared/api/types/society/[societyId]/app-route-config";

export const useHubCustomRouter = (): CustomRouterType => {
  const location = useLocation();
  const history = useHistory();

  const getFullPathAndParams = (path: string, params?: RouteParams) => {
    const fullParams = { ...(location.state || {}), ...params };
    const fullPath = generatePath(path, fullParams);
    return { fullPath, fullParams };
  };

  return {
    asPath: location.pathname,
    pathname: location.pathname,
    state: location.state,
    replace: (path: string, params?: RouteParams) => {
      const { fullPath, fullParams } = getFullPathAndParams(path, params);
      history.replace(fullPath, fullParams);
    },
    push: (path: string, params?: RouteParams) => {
      const { fullPath, fullParams } = getFullPathAndParams(path, params);
      history.push(fullPath, fullParams);
    },
  };
};

export const UserHubCustomRouterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useHubCustomRouter();
  return (
    <CustomRouterContext.Provider value={router}>
      {children}
    </CustomRouterContext.Provider>
  );
};
