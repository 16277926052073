import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "hub/src/api/client/query-client";
import MainContentWrapper from "hub/src/components/mainContent/MainContentWrapper";
import MainMenu from "hub/src/components/sideMenu/MainMenu";
import { UserHubCustomSessionProvider } from "hub/src/hooks/useHubCustomSession";
import { Settings as luxonSettings } from "luxon";
import { ENV } from "hub/src/appConstants/env";
import "../styles/index.css";
import UserHubRoutes from "hub/src/routes/UserHubRoutes";
import { UserHubCustomRouterProvider } from "hub/src/hooks/useHubCustomRouter";

luxonSettings.defaultZone = "America/Chicago";
setupIonicReact({
  mode: "md",
});

const App: React.FC = () => {
  return (
    <IonApp>
      <QueryClientProvider client={queryClient}>
        <IonReactRouter basename={ENV.REACT_BASE_PATH}>
          <UserHubCustomRouterProvider>
            <UserHubCustomSessionProvider>
              {/* TODO: We should pull the min-width value from our css variables for screen size of tablet
          to ensure it is consistent and managed in only one place. However, unsure of how to pull a css
          variable into html/js without a hacky, brittle solution.*/}
              <IonSplitPane contentId="main-content" when="(min-width: 768px)">
                {!ENV.REACT_APP_HIDE_SIDEBAR && <MainMenu />}
                <IonRouterOutlet
                  id="main-content"
                  basePath={ENV.REACT_BASE_PATH}
                >
                  <MainContentWrapper className="h-full w-full">
                    <UserHubRoutes />
                  </MainContentWrapper>
                </IonRouterOutlet>
              </IonSplitPane>
            </UserHubCustomSessionProvider>
          </UserHubCustomRouterProvider>
        </IonReactRouter>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </IonApp>
  );
};

export default App;
