import { BaseApiContract } from "shared/api/apiRequestContractHandler";
import { HttpMethod } from "shared/api/http";
import { SocietyFileInfoOutput } from "shared/api/types/society/[societyId]/files";
import { SocietyFileOrSlugParamsInput } from "shared/api/types/society/[societyId]/files/[fileIdOrSlug]";
export const getSocietyFileIdOrSlugContract: BaseApiContract<
  SocietyFileOrSlugParamsInput,
  undefined,
  SocietyFileInfoOutput | string
> = {
  method: HttpMethod.GET,
  apiRoute: "/api/v1/society/[societyId]/files/[fileIdOrSlug]",
  paramsType: {} as SocietyFileOrSlugParamsInput,
  responseType: {} as SocietyFileInfoOutput,
};
export const deleteSocietyFileIdOrSlugContract: BaseApiContract<
  SocietyFileOrSlugParamsInput,
  undefined,
  any
> = {
  method: HttpMethod.DELETE,
  apiRoute: "/api/v1/society/[societyId]/files/[fileIdOrSlug]",
  paramsType: {} as SocietyFileOrSlugParamsInput,
  responseType: undefined as unknown as any,
};
