import { Combobox } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { ErrorMessage, FormikValues, useFormikContext } from "formik";
import { get as lodashGet, isEmpty as lodashIsEmpty } from "lodash";
import React, { ComponentProps, ComponentPropsWithoutRef, ComponentType, useMemo, useState } from "react";
import ChevronUpDown from "shared/components/icons/chevron/ChevronUpDown";
import Check from "shared/components/icons/sign/Check";
import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import { QueryTableData } from "shared/components/pillar-table/PillarTable";
import Spinner from "admin/src/ui/components/common/Spinner";
import { useDebounce } from "admin/src/ui/hooks/useDebounce";

export type PillarFormComboboxOption<T = void> = {
  key: string;
  display: string;
  value: number | null | string;
  rawData?: T;
};
//TODO: This is a Stevenism,  it essentially negates our type saftey but headlessui/react doesnt seem to expose its ComboBox props
type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;
export type PillarFormComboboxProps<T extends object> = Omit<
ExtractProps<typeof Combobox>,
  "value" | "id" | "refName" | "as" | "onChange"
> & {
  name: string;
  valueProperty?: string;
  label?: string;
  displayProperty?: string;
  comboOptionQuery: QueryTableData<T> | PillarFormComboboxOption<T>[];
  createNewComboOptionQuery?: (query: string) => Promise<T>;
  allowNewOptions?: boolean;
  OptionComponent?: React.ComponentType<ComboboxOptionProps<T>>;
  customMappingFunction?: (value: T) => PillarFormComboboxOption<T>;
  placeholder?: string;
  localFiltering?: boolean;
  testidInput?: string;
  testidOption?: string;
  labelClassName?: string;
  additionalClasses?: string;
  optionsMenuClasses?: string;
  disabled?: boolean;
  nullable?: boolean;
};

export type ComboboxOptionProps<T = void> = {
  option: PillarFormComboboxOption<T>;
  isSelected: boolean;
};

export const ComboboxOptionDefault = <T = void,>({
  option,
  isSelected,
}: ComboboxOptionProps<T>) => {
  return (
    <Combobox.Option
      value={option.value}
      className="cursor-pointer rounded hover:bg-society-50 p-1 flex items-center text-neutral-mid-850"
    >
      {isSelected && (
        <span className="mr-0.5">
          <Check className="h-1.5 w-1.5" aria-hidden="true" />
        </span>
      )}
      <span className="ml-1 text-neutral-mid-850">{option.display}</span>
    </Combobox.Option>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PillarFormCombobox = <T extends { [key: string]: any }>({
  name,
  label,
  valueProperty,
  displayProperty,
  comboOptionQuery,
  createNewComboOptionQuery,
  allowNewOptions,
  OptionComponent = ComboboxOptionDefault,
  labelClassName,
  additionalClasses,
  optionsMenuClasses,
  customMappingFunction,
  placeholder,
  localFiltering,
  testidInput,
  testidOption,
  disabled,
  nullable,
  multiple,
  ...props
}: PillarFormComboboxProps<T>) => {
  if (
    allowNewOptions &&
    (!(comboOptionQuery instanceof Function) || !valueProperty)
  ) {
    throw new Error(
      "You can't create new options for static options list and must provide valueProperty"
    );
  }

  const { values, setFieldValue, errors } = useFormikContext<FormikValues>();
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    [query],
    500
  );

  const handleComboboxChange = async (
    value: (number | string | null)[] | number | string | null,
    query: string
  ) => {
    // case when new option needs to be created
    if (
      (value === null || (Array.isArray(value) && value?.includes?.(null))) &&
      allowNewOptions
    ) {
      const newComboOption = await createNewComboOptionQuery!(query);

      await comboOptions.refetch();

      if (value && Array.isArray(value)) {
        value = value.filter((option) => option !== null);

        if (newComboOption) {
          // We ensured that valueProperty must be provided when allowNewOptions is true
          value.push(lodashGet(newComboOption, valueProperty!));
        }
      }
    }

    const localOptionsCopy =
      comboOptionQuery instanceof Array ? [...comboOptionQuery] : [];

    if (
      query !== "" &&
      value &&
      Array.isArray(value) &&
      value.includes?.(query)
    ) {
      localOptionsCopy.push({
        key: query,
        display: query,
        value: query,
      });
    }
    if (lodashIsEmpty(value) && nullable) {
      value = null;
    }
    setFieldValue(name, value);
  };

  const comboOptions = useQuery(
    ["comboOptions", name, debouncedQuery],
    async (): Promise<PillarFormComboboxOption<T>[]> => {
      const response = await (comboOptionQuery as QueryTableData<T>)(
        { query },
        // TODO: need to implement actual pagination (infinite scroll ideally)
        undefined
      );

      const responseData: T[] =
        response instanceof Array ? response : response.results;

      const defaultMappingFunction = (
        option: T
      ): PillarFormComboboxOption<T> => ({
        key: lodashGet(option, valueProperty ?? "value"),
        display: lodashGet(option, displayProperty ?? "display"),
        value: lodashGet(option, valueProperty ?? "value"),
        rawData: option,
      });

      return responseData.map(customMappingFunction || defaultMappingFunction);
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: comboOptionQuery instanceof Function,
    }
  );

  const performLocalSearch = (
    array: PillarFormComboboxOption<T>[],
    query: string
  ): PillarFormComboboxOption<T>[] =>
    array.filter((option: PillarFormComboboxOption<T>) => {
      return option.display.toLowerCase().includes(query.toLowerCase());
    });

  const filteredOptions = useMemo(() => {
    if (comboOptions && comboOptions.data) {
      return localFiltering
        ? performLocalSearch(comboOptions.data, query)
        : comboOptions.data;
    } else if (comboOptionQuery instanceof Array) {
      return (
        performLocalSearch(
          comboOptionQuery as PillarFormComboboxOption<T>[],
          query
        ) ?? []
      );
    } else {
      return [];
    }
  }, [comboOptions, comboOptionQuery]);

  const findMatchingDataOption = (option: number) => {
    if (comboOptions.data) {
      return (
        comboOptions?.data?.find(
          (o: PillarFormComboboxOption<T>) => String(o.value) === String(option)
        )?.display ?? ""
      );
    } else if (Array.isArray(comboOptionQuery)) {
      return (
        comboOptionQuery?.find(
          (o: PillarFormComboboxOption<T>) => o.value === option
        )?.display ?? ""
      );
    } else {
      return "";
    }
  };

  const formSelectedOptionsString = (selectOptions: number & number[]) =>
    Array.isArray(selectOptions)
      ? selectOptions?.map(findMatchingDataOption).join(", ") ?? ""
      : findMatchingDataOption(selectOptions);

  if (comboOptions.isFetching)
    return <Spinner spinnerSize={SpinnerSize.Small} />;

  return (
    <>
      <Combobox
        id={name}
        refName="comboboxRef"
        as="div"
        value={values[name] || []}
        onChange={(value: (string | number | null)[]) => {
          handleComboboxChange(value, query);
        }}
        multiple={multiple}
        className={additionalClasses}
        disabled={disabled}
        {...props}
      >
        <Combobox.Label
          htmlFor={name}
          className={labelClassName}
          hidden={!label}
        >
          {label}:
        </Combobox.Label>
        <div className="relative">
          <Combobox.Input
            className="w-full pr-3 h-4.5"
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
            displayValue={(selectOptions: number[] & number) => {
              if (
                (comboOptions.data || Array.isArray(comboOptionQuery)) &&
                (selectOptions ?? false)
              ) {
                return formSelectedOptionsString(selectOptions);
              } else {
                return placeholder ?? "";
              }
            }}
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
              event.target.value =
                formSelectedOptionsString(values[name]) ?? placeholder ?? "";
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              event.target.value =
                formSelectedOptionsString(values[name]) ?? placeholder ?? "";
            }}
            data-testid={testidInput}
          />
          <Combobox.Button className="appcombo-button-container absolute right-0.25 h-4 group top-0.25">
            <ChevronUpDown
              className="h-2.5 w-2.5 group-hover:stroke-neutral-light"
              aria-hidden="true"
            />
          </Combobox.Button>
          <Combobox.Options
            className={`appcombo-options-container absolute z-10 mt-0.5 max-h-60 w-full overflow-auto ${
              optionsMenuClasses ?? ""
            }`}
            data-testid={testidOption}
          >
            {allowNewOptions &&
              query.length > 0 &&
              filteredOptions.length === 0 && (
                <Combobox.Option
                  value={null}
                  className="appcombo-options-option-add"
                  onClick={() => {
                    setQuery("");
                  }}
                >
                  <span>Create new item: {query}</span>
                </Combobox.Option>
              )}
            {filteredOptions.length > 0 &&
              filteredOptions.map((option: PillarFormComboboxOption<T>) => (
                <OptionComponent
                  key={option.key}
                  option={option}
                  isSelected={
                    multiple
                      ? values[name]?.includes?.(option.value)
                      : String(values[name]) === String(option.value)
                  }
                />
              ))}
          </Combobox.Options>
        </div>
        {errors[name] && (
          <ErrorMessage
            name={name}
            component="span"
            className="text-danger-small"
          />
        )}
      </Combobox>
    </>
  );
};

export default PillarFormCombobox;
