import dayjs from "dayjs";
import React, { useCallback, useContext } from "react";

import {
  PrimaryButton,
  SecondaryButton,
} from "admin/src/ui/components/common/datepicker/src/components/utils";
import { DATE_FORMAT } from "admin/src/ui/components/common/datepicker/src/constants";
import DatepickerContext from "admin/src/ui/components/common/datepicker/src/contexts/DatepickerContext";
interface FooterProp {
  filterkey?: string;
}

const Footer: React.FC<FooterProp> = ({ filterkey = "" }) => {
  // Contexts
  const { hideDatepicker, period, changeDatepickerValue, configs, classNames } =
    useContext(DatepickerContext);

  // Functions
  const getClassName = useCallback(() => {
    if (
      typeof classNames !== "undefined" &&
      typeof classNames?.footer === "function"
    ) {
      return classNames.footer();
    }

    return "flex items-center justify-end pb-0.5 pt-0.5 border-t border-neutral-mid-200";
  }, [classNames]);

  return (
    <div className={getClassName()}>
      <div className="w-full md:w-auto flex items-center justify-center">
        <SecondaryButton
          filterKey={filterkey}
          onClick={() => {
            hideDatepicker();
          }}
        >
          <>{configs?.footer?.cancel ? configs.footer.cancel : "Cancel"}</>
        </SecondaryButton>
        <PrimaryButton
          filterKey={filterkey}
          onClick={() => {
            if (period.start && period.end) {
              changeDatepickerValue({
                startDate: dayjs(period.start).format(DATE_FORMAT),
                endDate: dayjs(period.end).format(DATE_FORMAT),
              });
              hideDatepicker();
            }
          }}
          disabled={!(period.start && period.end)}
        >
          <>{configs?.footer?.apply ? configs.footer.apply : "Apply"}</>
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Footer;
