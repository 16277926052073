import React, { useContext } from "react";

import { RoundedButton } from "admin/src/ui/components/common/datepicker/src/components/utils";
import DatepickerContext from "admin/src/ui/components/common/datepicker/src/contexts/DatepickerContext";
import { generateArrayNumber } from "admin/src/ui/components/common/datepicker/src/helpers";
interface Props {
  year: number;
  currentYear: number;
  minYear: number | null;
  maxYear: number | null;
  clickYear: (data: number) => void;
}

const Years: React.FC<Props> = ({
  year,
  currentYear,
  minYear,
  maxYear,
  clickYear,
}) => {
  const { dateLooking } = useContext(DatepickerContext);

  let startDate = 0;
  let endDate = 0;

  switch (dateLooking) {
    case "backward":
      startDate = year - 11;
      endDate = year;
      break;
    case "middle":
      startDate = year - 4;
      endDate = year + 7;
      break;
    case "forward":
    default:
      startDate = year;
      endDate = year + 11;
      break;
  }

  return (
    <div className="w-full grid grid-cols-2 gap-2 mt-2">
      {generateArrayNumber(startDate, endDate).map((item, index) => (
        <RoundedButton
          key={index}
          padding="py-0"
          onClick={() => {
            clickYear(item);
          }}
          active={currentYear === item}
          disabled={
            (maxYear !== null && item > maxYear) ||
            (minYear !== null && item < minYear)
          }
        >
          <>{item}</>
        </RoundedButton>
      ))}
    </div>
  );
};

export default Years;
